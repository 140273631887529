<template>
  <div class="bigbox" style="margin-top: 30px; margin-left: 60px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.SelectApplication')" prop="appName">
        <el-input :disabled="true" size="small" v-model="ruleForm.appName" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderSelect')" style="width: 215px"></el-input>
        <el-upload :disabled="disabledApk" action="#" :auto-upload="false" :show-file-list="false" :on-success="handleAvatarSuccess1" class="el-button mar-class-8" style="padding: 0; margin-left: 12px; border: none" :on-change="changeUpload" multiple accept=".ipa">
          <el-button size="small" style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            ">
            <span style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              ">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{$t('AppManage.AppManagePage.UploadAndroidApp.SelectApplicationBtn')}}</span>
          </el-button>
        </el-upload>
        <el-progress v-if="flagStatus" :percentage="percentage" :color="customColorMethod" style="width: 230px"></el-progress>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.applyName')" prop="name">
        <el-input size="small" style="width:215px;" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderApplyName')" :disabled="analysisFlag" v-model.trim="ruleForm.name"></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.ApplicationID')" prop="packageName">
        <el-input size="small" style="width:215px;" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderApplicationID')" :disabled="analysisFlag" v-model.trim="ruleForm.packageName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.VersionNumber')" prop="versionName">
        <el-input size="small" style="width:215px;" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderVersionNumber')" :disabled="analysisFlag" v-model="ruleForm.versionName"></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.ApplicationCategory')" prop="appcategoryid">
        <el-select size="small" style="width: 215px" v-model="ruleForm.appcategoryid" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderApplicationCategory')">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.AppManagePage.UploadAndroidApp.Apptactics') + '：'"
      >
        <div style="width: 380px; margin: 8px 10px 0 0px; display: flex">
          <el-table
            ref="multipleTable"
            :data="dictData"
            stripe
            size="mini"
            tooltip-effect="dark"
            class="tableClass"
            :cell-style="iCellStyle"
            :header-row-style="iHeaderRowStyle"
            :header-cell-style="{
              background: '#f7f8fa',
              color: '#606266',
              padding: '0px',
            }"
            style="width: 100%; overflow-y: auto"
          >
            <el-table-column
              header-align="left"
              align="left"
              prop="name"
              :label="$t('AppManage.AppManagePage.UploadAndroidApp.AndroidName')"
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop=""
              :label="$t('public.Controls')"
              show-overflow-tooltip
            >
              <template>
                <img
                  @click="delapp"
                  src="@/images/icon_del.png"
                  alt=""
                  style="width: 15px; height: 15px; margin-left: 5px; cursor: pointer"
                  :title="$t('public.Delete')"
                />
              </template>
            </el-table-column>
          </el-table>
          <el-button
            v-if="changeTypeFlag"
            @click="addData"
            type="primary"
            size="mini"
            style="margin-left: 10px; border-radius: 15px; height: 30px"
            icon="el-icon-plus"
            >{{ $t("AppManage.AppManagePage.UploadAndroidApp.Apptactics") }}</el-button
          >
        </div>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.ApplicationIntroduction')">
        <el-input type="textarea" v-model="ruleForm.memo" style="width: 215px"></el-input>
      </el-form-item>
      
      <el-form-item>
        <el-button size="small" :loading="logdingFlag" @click="submitForm('ruleForm')" type="primary">{{$t('public.Save')}}</el-button>
      </el-form-item>
    </el-form>
    <Eldialog
      :num="'440px'"
      :width="'900px'"
      :title="$t('AppManage.AppManagePage.page.ApplicationStrategylist')"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
      :btnTitle="$t('public.Join')"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="joinType"
    >
      <div style="height: 438px">
        <el-input
          v-model="ipt"
          @input="(e) => (ipt = validForbid(e))"
          @keyup.enter.native="onSbumit"
          size="small"
          style="width: 180px; margin-bottom: 10px"
          :placeholder="$t('AppManage.AppManagePage.page.PolicyName')"
        ></el-input>
        <!-- v-model="ipt" -->
        <el-button
          @click="onSbumit"
          type="primary"
          size="small"
          style="margin-left: 3px"
          >{{ $t("public.Inquire") }}</el-button
        >
        <el-table
          size="mini"
          stripe
          ref="multipleTables"
          :data="tableDataNmae"
          :row-key="getRowKeys"
          class="sort_table"
          tooltip-effect="dark"
          style="width: 97%"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
        >
          <el-table-column align="center" width="30" :reserve-selection="true">
            <template v-slot="{ row }">
              <el-radio-group
                @change="handleSelectionChange(row)"
                v-model="ruleForm.apppermissionid"
              >
                <el-radio :label="row.id">&nbsp;</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('AppManage.ApplicationStrategy.CurrentPage.PolicyList.PolicyName')"
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="ostypeid"
            :label="$t('public.OperatingSystem')"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.osTypeId == 20"> Android </template>
              <template v-else> iOS </template>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="groupfullname"
            :label="$t('public.Section')"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="lastupdatetime"
            :label="$t('public.ModificationTime')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.time == ''"></span
              ><span class="detaildesc" v-else>
                {{ scope.row.lastUpdateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <pagination
            :page="listData.currentPage"
            :limit="listData.pageSize"
            :total="listData.total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          />
        </div>
      </div>
    </Eldialog>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import Eldialog from "@/components/elDialog";
import dialogInfo from '@/components/promptMessage/index'
import pagination from "@/components/pagination/page.vue"; //分页页
export default {
  components: { dialogInfo,Eldialog,pagination },
  props: {
    androidData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    disabledApk: {
      type:Boolean,
      default:false
    }
  },
  created() {
    this.Resourcesas();
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
    if (this.androidData.apptype == false) {
      this.ruleForm = this.androidData
      this.purgeStatus = this.androidData.purgeStatus;
      this.multipleSelection.id = this.androidData.apppermissionid
      this.multipleSelection.name = this.androidData.appPermissionName
      this.purgeStatus = this.androidData.purgeStatus
      // this.dictData.push({'id':this.androidData.apppermissionid,'name':this.androidData.appPermissionName})
      this.$set(this.ruleForm, 'name', this.androidData.appName)
    } else if (this.androidData.apptype == undefined) {
      console.log('是新增呀！！！！！')
      // this.ruleForm.appcategoryid = 1
      this.ruleForm.appcategoryid = ""
    }
    this.selectData()
  },
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t('AppManage.AppManagePage.UploadAndroidApp.Error5')))
      } else {
        callback()
      }
    }
    return {
      purgeStatus:"",
      ipt: "", //弹框搜索
      dictData:[],
      changeTypeFlag:true,
      groupFullNameShowByIndex: "", //用户部门截取的下标
      statusVisible:false,
      tableDataNmae: [], //资源列表
      multipleSelection:{},
      // 列表参数
      listData: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      },
      parseStatus:0,
      analysisFlag:true,
      logdingFlag: false,
      flagStatus: false,
      percentage: 0,
      titleInfo: '保存成功',
      visibaelFlag: false,
      checked: true, // 安装类型的多选框
      queryObj: {
        manage: 1,
        selectcont: '' //下拉矿的值
      },
      options: [],
      ruleForm: {
        appName: '', //
        region: '', //
        selectcont: '', //下拉矿的值
        appcategoryid: '',
        osType: '20',
        name: '',
        packageName:'',
        versionName:'',
        apppermissionid:''
      },
      path: '',
      rules: {
        appName: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.appName'), trigger: 'blur' },
          { validator: validateInput, trigger: ['blur', 'change'] }
        ],
        appcategoryid: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.appcategoryid'), trigger: 'change' }
        ],
        name: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.name'), trigger: 'blur' }
        ],
        packageName: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.packageName'), trigger: 'blur' }
        ],
        versionName: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.versionName'), trigger: 'blur' },
        ]
      }
    }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
    setTimeout(() => {
      this.ruleForm.apppermissionid = this.ruleForm.apppermissionid + "";
      this.edittableDataNmae.forEach((item, index) => {
        if (item.id == this.ruleForm.apppermissionid) {
          this.dictData.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      console.log(this.dictData,'回想爱你');
      this.$forceUpdate();
    }, 1500);
  },
  methods: {
    checkSpecVersion(str) {
        var specialKey =/^([a-zA-Z0-9]){1}(\w|\.|\s*)+$/
        if (specialKey.test(str)) {
          return false
        }
      return true
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    checkSpecificKey(str) {
      var specialKey =
        "/\ |\/|\~|\!|\@|\#|\\$|\%|\^|\&|\*|\(|\)|\+|\{|\}|\:|\<|\>|\?|\[|\]|\,|\/|\;|\'|\`|\=|\\\|\|/"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },
    mathdom() {
      var num = Math.floor(Math.random() * 10 + 1)
      return num
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#909399'
      } else if (percentage < 70) {
        return '#e6a23c'
      } else {
        return '#67c23a'
      }
    },
    // 添加应用策略
    addData() {
      this.listData.currentPage=1
      this.statusVisible = true;
      this.Resources();
      // if(this.dictData.length == 0){
      //   this.multipleSelection.id = "";
      // }
    },
    // 添加资源列表
    async Resources() {
      // if (this.ipt.length > 0) {
      //   this.listData.currentPage = 1
      // }
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        name: this.ipt,
        osTypeId: 10,
      };
      console.log(param, "paramparam");
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/getDataInfo",
        param,
        true
      );
      console.log(res, "eeeeeeeeeeeee");
      this.tableDataNmae = res.data.content.filter((item) => {
        // console.log(item.osTypeId===20,"00000110");
        return item.osTypeId == 10;
      }); //列表数据
      console.log(res.data.content, "网关资源列表数据");
      this.listData.total = res.data.total; //列表总数
      console.log(this.dictData, "this.dictData");
      this.$emit("xingwei", this.permission);
    },
    // 编辑添加资源列表
    async Resourcesas() {
      // if (this.ipt.length > 0) {
      //   this.listData.currentPage = 1
      // }
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: 9999,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        name: this.ipt,
        osTypeId: 10,
      };
      console.log(param, "paramparam");
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/getDataInfo",
        param,
        true
      );
      console.log(res, "eeeeeeeeeeeee");
      this.edittableDataNmae = res.data.content.filter((item) => {
        // console.log(item.osTypeId===20,"00000110");
        return item.osTypeId == 10;
      }); //列表数据
      this.listData.total = res.data.total; //列表总数
      console.log(this.dictData, "this.dictData");
      this.$emit("xingwei", this.permission);
    },
    heightCustom() {
      this.height.height = this.num;
    },
    // 弹框全选
    getRowKeys(row) {
      return row.id;
    },
    // 添加资源   选中
    handleSelectionChange(val) {
      console.log(val, "val选中");
      this.multipleSelection = val;
    },
    // 添加
    joinType() {
      this.dictData = [];
      // console.log(this.multipleSelection.id.length,'');
      if(this.multipleSelection.id){
        this.dictData.push({
          id: this.multipleSelection.id,
          name: this.multipleSelection.name,
        });
        console.log(this.dictData, "this.dictData000000");
      }
      this.waters = [];
      this.waters.push({
        id: this.multipleSelection.id,
      });
      this.statusVisible = false; 
    },
    delapp() {
      this.waters = this.dictData = undefined;
      this.ruleForm.apppermissionid = "";
      this.dictData = []; 
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.listData.currentPage = page;
      this.listData.pageSize = limit;
      // 调用列表
      this.Resources();
    },
    // 资源列表查询
    onSbumit() {
      this.listData.currentPage = 1
      this.Resources();
    },
    async saveSubmit() {
      this.logdingFlag = true
      if (this.ruleForm.id) {
        let params = {
          id: this.ruleForm.id + '',
          versionName: this.ruleForm.versionName,
          packageName: this.ruleForm.packageName,
          appCategoryId: this.ruleForm.appcategoryid,
          appType: this.ruleForm.appType ? '2' : '1',
          name: this.ruleForm.name,
          memo: this.ruleForm.memo,
          apppermissionid:this.androidData.apppermissionid,
          parseStatus:this.parseStatus,
          purgeStatus: this.purgeStatus
        }
        // console.log(params)
        const res = await this.$axios.post(
          '/httpServe/entApp/update',
          params,
          true
        )
        if (res.code == 200) {
          this.logdingFlag = false
          this.$emit('haldesotr')
        }
      } else {
        let params = {
          appName: this.ruleForm.appName,
          path: this.path,
          appCategoryId: this.ruleForm.appcategoryid,
          appType: this.ruleForm.appType ? '2' : '1',
          memo: this.ruleForm.memo,
          name: this.ruleForm.name,
          versionName: this.ruleForm.versionName,
          packageName: this.ruleForm.packageName,
          parseStatus:this.parseStatus,
          apppermissionid:this.ruleForm.apppermissionid,
          osType: '10',
          purgeStatus: this.purgeStatus
        }
        // console.log(params)
        const res = await this.$axios.post(
          '/httpServe/entApp/save',
          params,
          true
        )
        // console.log(res)
        if (res.data == 0) {
          // this.titleInfo = '上传应用名称重复'

          this.logdingFlag = false
          this.ruleForm = {}
          // this.visibaelFlag = true
          this.$message({
            message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error'),
            type: 'error',
            offset: 100
          })
        }
        if (res.data >= 1) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm = {}
          this.ruleForm.apppermissionid = ''
          this.ruleForm.memo = ''
          this.logdingFlag = false
          this.ruleForm.appType = false
          this.$emit('haldesotr')
        }
      }
    },
    async selectData() {
      // 应用类别----下拉框接口
      var param = {
        currentPage: 1,
        pageSize: 999999,
        appCategory: '',
        // orderColume: '',
        // orderRule: ''
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/getDataInfo',
        param,
        true
      )
      // console.log(res)
      this.options = res.data.content
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    },
    handleClose() {
      this.ipt = "";
      this.listData.currentPage = 1;
      this.visibaelFlag = false;
      this.statusVisible = false;
    },
    //文件上传接口
    async changeUpload(file) {
      this.percentage = 0
      this.flagStatus = true
      // console.log(file.name)
      // if (/[\u4E00-\u9FA5]/g.test(file.name)) {
      //     this.$message({
      //     message: '应用名称不能为中文',
      //     type: 'error',
      //     offset: 100
      //   })
      //   this.flagStatus = false
      //   return false
      // }
      // if (file.size / (1024 * 1024) > 500) {
      //   // 限制文件大小
      //   // this.$message.warning(`当前限制文件大小不能大于500M`)
      //   this.$message({
      //     message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error2'),
      //     type: 'error',
      //     offset: 100
      //   })
      //   return false
      // }
      let suffix = this.getFileType(file.name) //获取文件后缀名
      let suffixArray = ['ipa'] //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$message({
          message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error1'),
          type: 'error',
          offset: 100
        })
      } else {
        // if (file.size / (1024 * 1024) > 70) {
        //   //大于100m
        //   let timer = setInterval(() => {
        //     this.percentage += this.mathdom()
        //     if (this.percentage >= 90) {
        //       this.percentage = 95
        //       clearInterval(timer)
        //     }
        //   }, 1500)
        // }
        // if (file.size / (1024 * 1024) < 70) {
        //   //判断文件小于50m
        //   let timer = setInterval(() => {
        //     this.percentage += this.mathdom()
        //     if (this.percentage >= 90) {
        //       this.percentage = 95
        //       clearInterval(timer)
        //     }
        //   }, 100)
        // }
        const form = new FormData()
        // 文件对象
        form.append('files', file.raw)
        form.append('type', 'protected')
        form.append('fileType', 'ipa')
        form.append('moduleName', 'app')
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          '/httpServe/sysFile/fileUp',
          form,
          this.updateProgress,
          true
        )
        this.path = res.data.data[0]
        if (res.data.code == 200) {
          // this.titleInfo = '上传应用成功'
          // this.visibaelFlag = true
          this.$message({
            message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Success'),
            type: 'success',
            offset: 100
          })
          this.parseAppProgress()
          this.flagStatus = false
        } else {
          // this.titleInfo = '上传应用失败'
          // this.visibaelFlag = true
          this.$message({
            message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error3'),
            type: 'error',
            offset: 100
          })
          this.flagStatus = false
        }
        this.$nextTick(() => {
          this.$set(this.ruleForm, 'appName', file.name)
        })
        if (res.code == 200) {
          this.$emit('haldesotr')
        }
      }
    },
    async parseAppProgress() {
      let params = {
        path:this.path
      }
      const res = await this.$axios.post(
        '/httpServe/entApp/parseApp',
        params,
        true
      )
      if(res.data.parseStatus == 0) {
        this.$set(this.ruleForm,'name',res.data.name)
        this.$set(this.ruleForm,'packageName',res.data.packagename)
        this.$set(this.ruleForm,'versionName',res.data.versionname)
        if(this.ruleForm.name == "" || this.ruleForm.packageName == "" || this.ruleForm.versionName == "") {
          this.analysisFlag = false
        }
        this.parseStatus = res.data.parseStatus
      }
      if(res.data.parseStatus == 1) {
        this.analysisFlag = false
        this.$message({
            message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error4'),
            type: 'error',
            offset: 100
          })
      }
      this.purgeStatus = res.data.purgeStatus;
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100)
    },
    handleAvatarSuccess1() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
