<template>
  <div class="bigbox" style="margin-top: 30px; margin-left: 60px">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
      <el-form-item :label="$t('AppManage.AppManagePage.UploadH5App.SelectPicture')" prop="appName">
        <el-input :disabled="true" size="small" v-model="ruleForm.appName" :placeholder="$t('AppManage.AppManagePage.UploadH5App.placeholderSelectPicture')" style="width: 215px"></el-input>
        <el-upload :disabled="disabledApk" action="#" :auto-upload="false" :show-file-list="false" :on-success="handleAvatarSuccess1" class="el-button mar-class-8" style="padding: 0; margin-left: 12px; border: none" :on-change="changeUpload" multiple accept=".png">
          <el-button size="small" style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            ">
            <span style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              ">
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{$t('AppManage.AppManagePage.UploadH5App.SelectPictureBtn')}}</span>
          </el-button>
        </el-upload>
        <span style="margin-left:10px;color:#C0C4CC;">{{$t('AppManage.AppManagePage.UploadH5App.Support')}}</span>
        <el-progress v-if="flagStatus" :percentage="percentage" :color="customColorMethod" style="width: 230px"></el-progress>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.applyName')" prop="department">
        <el-input :disabled="disabledApk" size="small" style="width:215px;" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderApplyName')" v-model.trim="ruleForm.department"></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.ApplicationID')" prop="AppId">
        <el-input :disabled="disabledApk" size="small" style="width:215px;" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderApplicationID')" v-model.trim="ruleForm.AppId"></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.VersionNumber')" prop="version">
        <el-input :disabled="disabledApk" size="small" style="width:215px;" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderVersionNumber')" v-model="ruleForm.version"></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadH5App.UrlAddress')" prop="urls">
        <el-input size="small" style="width:215px;" :placeholder="$t('AppManage.AppManagePage.UploadH5App.placeholderUrlAddress')" v-model="ruleForm.urls"></el-input>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.ApplicationCategory')" prop="appcategoryid">
        <el-select size="small" style="width: 215px" v-model="ruleForm.appcategoryid" :placeholder="$t('AppManage.AppManagePage.UploadAndroidApp.placeholderApplicationCategory')">
          <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item :label="$t('AppManage.AppManagePage.UploadAndroidApp.ApplicationIntroduction')">
        <el-input type="textarea" v-model="ruleForm.memo" style="width: 215px"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" :loading="logdingFlag" @click="submitForm('ruleForm')" type="primary">{{$t('public.Save')}}</el-button>
      </el-form-item>
    </el-form>
    <dialog-info :dialogInfo="visibaelFlag" @handleClose="handleClose" :titleInfo="titleInfo" :width="'240px'" />
  </div>
</template>
<script>
import dialogInfo from '@/components/promptMessage/index'
export default {
  components: { dialogInfo },
  props: {
    androidData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    disabledApk: {
      type:Boolean,
      default:false
    }
  },
  data() {
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecificKey(value)) {
        callback(new Error(this.$t('AppManage.AppManagePage.UploadAndroidApp.Error5')))
      } else {
        callback()
      }
    }
    // var validateVersion = (rule, value, callback) => {
    //   if (this.checkSpecVersion(value)) {
    //     callback(new Error(this.$t('AppManage.AppManagePage.UploadAndroidApp.Error6')))
    //   } else {
    //     callback()
    //   }
    // }
    // 验证域名
    const validateName = (rule, value, callback) => {
      // IP正则
      let reg =
        /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/
      let regIpv6 =
        /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/
      let valdata = value.split(',')
      let isCorrect = true
      if (valdata.length) {
        for (let i = 0; i < valdata.length; i++) {
          if (
            reg.test(valdata[i]) == false &&
            regIpv6.test(valdata[i]) == false
          ) {
            isCorrect = false
          }
        }
      }

      var IP =
        '^((25[0-5]|2[0-4]\\d|[1]{1}\\d{1}\\d{1}|[1-9]{1}\\d{1}|\\d{1})($|(?!\\.$)\\.)){4}$'
      var IPtest = new RegExp(IP)
      
      // 域名正则
      const nameRegex = /(http|https):\/\/([\w.]+\/?)\S*/
        // /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/

      if (!IPtest.test(value)) {
        // callback(new Error('请输入正确的ip地址'));
        this.ipFlag = 0
        if (!nameRegex.test(value)) {
          // callback(new Error("请输入正确的域名，如：www.baidu.com"));
          // debugger;
          this.ipFlag = 0
          callback(new Error(this.$t('AppManage.AppManagePage.UploadH5App.Error')))
        } else {
          // this.ruleForm.gwType=1
          this.ipFlag = 1
          if (!nameRegex.test(value)) {
            callback(new Error(this.$t('AppManage.AppManagePage.UploadH5App.Error1')))
          } else {
            callback()
          }
        }
      } else {
        if (!isCorrect) {
          callback(new Error(this.$t('AppManage.AppManagePage.UploadH5App.Error2')))
        } else {
          this.ipFlag = 0
          callback()
        }
      }
    }
    return {
      logdingFlag: false,
      flagStatus: false,
      percentage: 0,
      titleInfo: '保存成功',
      visibaelFlag: false,
      checked: true, // 安装类型的多选框
      queryObj: {
        manage: 1,
        selectcont: '' //下拉矿的值
      },
      options: [],
      ruleForm: {
        appName: '', //
        region: '', //
        selectcont: '', //下拉矿的值
        appcategoryid: '',
        osType: '20',
        department: '',
        AppId:'',
        version:'',
        urls:"",
        memo:""
      },
      path: '',
      rules: {
        appName: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadH5App.rules.picture'), trigger: ['blur', 'change'] }
        ],
        appcategoryid: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.appcategoryid'), trigger: 'change' }
        ],
        department: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadH5App.rules.appName'), trigger: 'blur' },
          { validator: validateInput, trigger: ['blur', 'change'] }
        ],
        AppId: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.packageName'), trigger: 'blur' },
          { validator: validateInput, trigger: ['blur', 'change'] }
        ],
        version: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadAndroidApp.rules.versionName'), trigger: 'blur' },
          // { validator: validateVersion, trigger: ['blur', 'change'] }
        ],
        urls: [
          { required: true, message: this.$t('AppManage.AppManagePage.UploadH5App.rules.urls'), trigger: 'blur' },
          { validator: validateName, trigger: ['blur', 'change'] }
        ]
      }
    }
  },
  created() {
    this.selectData()
    if(this.androidData.id) {
      let appName = this.androidData.iconPathH5.split("/")
      this.$set(this.ruleForm,'appName',appName[appName.length-1])
      this.$set(this.ruleForm,'appcategoryid',this.androidData.appcategoryid)
      this.$set(this.ruleForm,'department',this.androidData.appName)
      this.$set(this.ruleForm,'version',this.androidData.versionName)
      this.$set(this.ruleForm,'urls',this.androidData.urlschemes)
      this.$set(this.ruleForm,'memo',this.androidData.memo)
      this.$set(this.ruleForm,'AppId',this.androidData.packageName)
      this.path = ""
    }
  },
  methods: {
    checkSpecificKey(str) {
      var specialKey =
        "[`~!#$^&*()=|{}':;',\\[\\]<>/?~！#￥……&*（）——%@|{}【】‘；：”“'。，、？’]‘'"
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false
        }
      }
      return true
    },
    checkSpecVersion(str) {
        var specialKey =/^([a-zA-Z0-9]){1}(\w|\.|\s*)+$/
        if (specialKey.test(str)) {
          return false
        }
      return true
    },
    mathdom() {
      var num = Math.floor(Math.random() * 10 + 1)
      return num
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return '#909399'
      } else if (percentage < 70) {
        return '#e6a23c'
      } else {
        return '#67c23a'
      }
    },
    async saveSubmit() {
      this.logdingFlag = true
      if (this.androidData.id) {
        let params = {
          id: this.androidData.id+'',
          name:this.ruleForm.department,
          packageName:this.ruleForm.AppId,
          versionName:this.ruleForm.version,
          urlSchemes:this.ruleForm.urls,
          appCategoryId: this.ruleForm.appcategoryid,
          filePath:this.path,
          memo:this.ruleForm.memo,
        }
        // console.log(params)
        const res = await this.$axios.post(
          '/httpServe/entApp/updateH5',
          params,
          true
        )
        if (res.data == 0) {
          this.logdingFlag = false
          this.$message({
            message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error'),
            type: 'error',
            offset: 100
          })
        }
        if (res.data == 1) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm = {}
          this.ruleForm.memo = ''
          this.logdingFlag = false
          this.$emit('haldesotr')
        }
      } else {
        let params = {
          name:this.ruleForm.department,
          packageName:this.ruleForm.AppId,
          versionName:this.ruleForm.version,
          urlSchemes:this.ruleForm.urls,
          appCategoryId: this.ruleForm.appcategoryid,
          filePath:this.path,
          memo:this.ruleForm.memo,
        }
        const res = await this.$axios.post(
          '/httpServe/entApp/saveH5',
          params,
          true
        )
        if (res.data == 0) {
          this.logdingFlag = false
          this.$message({
            message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error'),
            type: 'error',
            offset: 100
          })
        }
        if (res.data == 1) {
          this.$refs.ruleForm.resetFields()
          this.ruleForm = {}
          this.ruleForm.memo = ''
          this.logdingFlag = false
          this.$emit('haldesotr')
        }
      }
    },
    async selectData() {
      // 应用类别----下拉框接口
      var param = {
        currentPage: 1,
        pageSize: 999999,
        appCategory: '',
        // orderColume: '',
        // orderRule: ''
      }
      const res = await this.$axios.post(
        '/httpServe/entAppCategory/getDataInfo',
        param,
        true
      )
      // console.log(res)
      this.options = res.data.content
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit()
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf('.')
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase()
      } else {
        return ''
      }
    }, 
    handleClose() {
      this.visibaelFlag = false
    },
    //文件上传接口
    async changeUpload(file) {
      this.percentage = 0
      this.flagStatus = true
      let suffix = this.getFileType(file.name) //获取文件后缀名
      let suffixArray = ['png'] //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$set(this.ruleForm,"appName","")
        this.flagStatus = false
        this.$message({
          message: this.$t('AppManage.AppManagePage.UploadH5App.Error3'),
          type: 'error',
          offset: 100
        })
      } else if (file.size / (1024 * 1024) > 0.3) {
        //   限制文件大小
        this.$message.warning(this.$t('AppManage.AppManagePage.UploadH5App.Error4'));
        this.flagStatus = false
        return false;
      } else {
        const form = new FormData()
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "img");
        form.append("moduleName", "protected");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          '/httpServe/sysFile/fileUp',
          form,
          this.updateProgress,
          true
        )
        if(res.data.code == 500) {
          this.$message({
            message: '返回上传地址信息失败！',
            type: 'error',
            offset: 100
          })
          this.flagStatus = false
        }
        this.path = res.data.data[0]
        if (this.path) {
          // this.titleInfo = '上传应用成功'
          // this.visibaelFlag = true
          this.$message({
            message: this.$t('AppManage.AppManagePage.UploadH5App.Success'),
            type: 'success',
            offset: 100
          })
          this.flagStatus = false
        } else {
          // this.titleInfo = '上传应用失败'
          // this.visibaelFlag = true
          this.$message({
            message: this.$t('AppManage.AppManagePage.UploadAndroidApp.Error3'),
            type: 'error',
            offset: 100
          })
          this.flagStatus = false
        }
        this.$nextTick(() => {
          this.$set(this.ruleForm, 'appName', file.name)
        })
        if (res.code == 200) {
          this.$emit('haldesotr')
        }
      }
    },
    updateProgress(e) {
       //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100)
    },
    handleAvatarSuccess1() {}
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>