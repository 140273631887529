<template>
  <div class="limit">
    <div class="limitBox">
      <!-- 设备信息 -->
      <div class="protection">
        <div class="protectionWord">{{$t('AppManage.AppManagePage.ExportPage.DeviceInformation')}}</div>
        <div class="checkbox" style="margin-top: 10px">
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.deviceName')"
            v-model="limit.deviceName"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.healthDegree')"
            v-model="limit.healthDegree"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.isViolation')"
            v-model="limit.isViolation"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.isJailBreak')"
            v-model="limit.isJailBreak"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.status')"
            v-model="limit.status"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.bindStatus')"
            v-model="limit.bindStatus"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox> 
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.conStatus')"
            v-model="limit.conStatus"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.battery')"
            v-model="limit.battery"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.availMemorySize')"
            v-model="limit.availMemorySize"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.availDataSize')"
            v-model="limit.availDataSize"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.version')"
            v-model="limit.version"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.isActive')"
            v-model="limit.isActive"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.deviceDay')"
            v-model="limit.deviceDay"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.deviceTime')"
            v-model="limit.deviceTime"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.networkOperatorName')"
            v-model="limit.networkOperatorName"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
        </div>
      </div>
      <div class="setting">
        <div class="checkbox" style="margin-top: 10px">
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.brand')"
            v-model="limit.brand"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.model')"
            v-model="limit.model"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.serial')"
            v-model="limit.serial"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.osId')"
            v-model="limit.osId"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.osVersion')"
            v-model="limit.osVersion"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.surfType')"
            v-model="limit.surfType"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.number')"
            v-model="limit.number"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.sdk')"
            v-model="limit.sdk"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.imei')"
            v-model="limit.imei"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.deviceUDID')"
            v-model="limit.deviceUDID"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.signStatus')"
            v-model="limit.signStatus"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.belong')"
            v-model="limit.belong"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.lastLoginTime')"
            v-model="limit.lastLoginTime"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
        </div>
      </div>
      <!-- 用户信息 -->
      <div class="setting">
        <div class="settingWord">{{$t('AppManage.AppManagePage.ExportPage.UserInformation')}}</div>
        <div class="checkbox" style="margin-top: 10px">
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.loginName')"
            v-model="limit.loginName"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.userName')"
            v-model="limit.userName"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.userSex')"
            v-model="limit.userSex"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.groupFullName')"
            v-model="limit.groupFullName"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.phone')"
            v-model="limit.phone"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.email')"
            v-model="limit.email"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
        </div>
      </div>
      <!-- 应用信息 -->
      <div class="setting">
        <div class="settingWord">{{$t('AppManage.AppManagePage.ExportPage.AppInformation')}}</div>
        <div class="checkbox" style="margin-top: 10px">
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.appName')"
            v-model="limit.appName"
            true-label="1"
            false-label="0"
            @change="limits"
            :disabled="true"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.appId')"
            v-model="limit.appId"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.versionName')"
            v-model="limit.versionName"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.appSize')"
            v-model="limit.appSize"
            true-label="1"
            false-label="0"
          ></el-checkbox>
          <el-checkbox
            :label="$t('AppManage.AppManagePage.ExportPage.installStatus')"
            v-model="limit.installStatus"
            true-label="1"
            false-label="0"
            @change="limits"
          ></el-checkbox>
        </div>
      </div>
    </div>
    <el-button
      style="margin: 20px 150px; width: 120px"
      size="small"
      type="primary"
      @click="exportClick"
      >{{$t('public.Derive')}}</el-button
    >
  </div>
</template>
<script>
export default {
  props: {
    searchVal: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      limit: {
        appName: "1",
        appId: "1",//appUdid
        appSize: "1",
        versionName: "1",
        allowSendSMS: "",
        scan_score: "",
        purge_status: "",
        id: "",
        deviceName: "1",
        healthDegree: "",
        isViolation: "1",
        isJailBreak: "",
        status: "1",
        bindStatus:"1",
        conStatus: "1",
        battery: "",
        availMemorySize: "",
        availDataSize: "",
        version: "",
        isActive: "",
        deviceDay: "1",
        deviceTime: "1",
        brand: "1",
        model: "1",
        serial: "",
        osId: "1",
        osVersion: "1",
        surfType: "1",
        number: "",
        sdk: "",
        imei: "1",
        deviceUDID: "",
        signStatus: "",
        belong: "1",
        lastLoginTime: "1",
        phoneType: "",
        netWorkType: "",
        device: "",
        product: "",
        hardware: "",
        deviceSoftwareVersion: "",
        subscriberId: "",
        hardwarBrand: "",
        systemBrand: "",
        hardwarSerial: "",
        voiceMailNumber: "",
        networkOperatorName: "1",
        networkCountryIso: "",
        board: "",
        releaseVersionId: "",
        hasIccCard: "",
        loginName: "1",
        userName: "1",
        userSex: "",
        groupFullName: "1",
        phone: "1",
        email: "1",
        installStatus: "1",
      },
      userId: "1",
      flagStatus: false,
    };
  },
  created() { },
  mounted(){
 
  },
  methods: {
    limits() {
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    changeEvent() {
      this.$emit("submitSave", {
        limit: this.limit,
      });
    },
    async exportClick() {
      let data = [];
      for (const key in this.limit) {
        if (this.limit[key] == 1) {
          data.push(key);
        }
      }
      let token2 = sessionStorage.getItem("Authorization")
        ? sessionStorage.getItem("Authorization")
        : "eyJ0eXBlIjoiSldUIiwiYWxnIjoiSFM1MTIifQ.eyJleHAiOjE2NTIxNjkwOTMsInVzZXJJZCI6MSwiaWF0IjoxNjUyMTQ3NDkzfQ.IW5YpDyKCJcquEyVtHJFr-5S_9C2g3__cuk8wJK4YYSltBjQRuRwGmNVHaztoaLBEQDX0J-4PX2l1lIUeaRWgQ";
      let userString = decodeURIComponent(
        escape(window.atob(token2.split(".")[1]))
      );
      this.userId = JSON.parse(userString).userId;
      console.log(this.userId)
      // var appOsType=""
      // var app_ame =""
      // if(this.$route.query.jumpPageName){
      //   console.log(this.$route.query.appType,"this.$route.query.appType")

      //   if(this.$route.query.appType=='iOS'){
      //     appOsType='10'
      //     app_ame=this.$route.query.appname
      //   }else if(this.$route.query.appType=='Android'){
      //     appOsType='20'
      //     app_ame=this.$route.query.appname
      //   }
      // }
      console.log(this.searchVal,"this.$route.query.jumpPageName")
      var params = {
        exportField: data,
        appName:this.searchVal.name,
	      osTypeId:this.searchVal.osType,
        currentPage: this.searchVal.pageNumber,
        pageSize: this.searchVal.rowNumber,
        orderColume: this.searchVal.orderColume,
        orderRule: this.searchVal.orderRule,
        appType: this.searchVal.apptype,
        appStatus: this.searchVal.statu,
      };
      const res = await this.$axios.postExport(
        "/httpManageExport/entApp/exportFile",
        params,
        false,
        this.userId
      );
      console.log(res);
      const link = document.createElement("a");
      console.info(new Date());
      var fileName = "exportApp_" + Date.parse(new Date()) + ".xls";
      let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
      link.style.display = "none";
      link.href = URL.createObjectURL(blob);
      link.setAttribute("download", decodeURI(fileName));
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang='scss' scoped>
.limit {
  padding-top: 20px;
  width: 100%;
  height: 100%;
}
.limitBox {
  width: 100%;
  display: flex;
}
.el-radio-group {
  margin-left: 150px;
}
.protection {
  width: 310px;
  display: flex;
  .protectionWord {
    width: 150px;
    text-align: right;
    height: 24px;
    line-height: 24px;
    margin: 8px 0;
    font-size: 14px;
  }
}
.checkbox {
  width: 160px;
  .el-checkbox {
    height: 24px;
    line-height: 24px;
  }
}
.setting {
  width: 200px;
  display: flex;
  .settingWord {
    width: 100px;
    text-align: right;
    height: 30px;
    line-height: 30px;
    margin: 8px 0;
    font-size: 14px;
  }
}
::v-deep .el-radio__label {
  font-size: 12px;
  color: #807e7e;
}
::v-deep .el-checkbox__label {
  font-size: 14px;
  color: #807e7e;
}
::v-deep .el-checkbox__input.is- + span.el-checkbox__label {
  color: #807e7e;
  line-height: 35px;
  margin: 10px 0;
}
::v-deep .el-radio__input.is- + span.el-radio__label {
  color: #807e7e;
}
</style>
