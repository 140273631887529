<template>
  <div class="box processManage">
    <div>
      <div class="one" v-show="isHidden == 1">
        <el-row :gutter="4" class="mt15" style="display: flex">
          <el-col :span="2">
            <el-select
              style="width: 100%"
              size="small"
              v-model="queryObj.apptype"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options1"
                :key="item.value"
                :label="$t(`AppManage.AppManagePage.page.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <!-- 安装模式 -->
          <el-col :span="2">
            <el-select
              style="width: 100%"
              size="small"
              v-model="queryObj.installType"
              placeholder="请选择"
            >
              <el-option
                v-for="item in Installmode"
                :key="item.value"
                :label="$t(`AppManage.AppManagePage.page.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="2">
            <el-select
              style="width: 100%"
              size="small"
              v-model="queryObj.statu"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options3"
                :key="item.value"
                :label="$t(`AppManage.AppManagePage.page.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <!-- 操作系统 -->
          <el-col :span="2">
            <el-select
              style="width: 100%"
              size="small"
              placeholder="请选择"
              v-model="queryObj.osType"
            >
              <el-option
                v-for="item in osTypeOptions"
                :key="item.value"
                :label="$t(`AppManage.AppManagePage.page.${item.label}`)"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="3">
            <el-input
              v-model="queryObj.name"
              size="small"
              :placeholder="$t('AppManage.AppManagePage.page.placeholder')"
              @keyup.enter.native="getServerName"
            ></el-input>
          </el-col>
          <el-col :span="4">
            <el-button
              size="small"
              type="primary"
              v-debounce:500="searchList"
              >{{ $t("public.Inquire") }}</el-button
            >
          </el-col>
        </el-row>
        <el-row style="padding: 0px 10px 10px 10px">
          <el-col :span="24">
            <el-button-group>
              <el-button @click="isHidden = 2" type="primary" size="small">{{
                $t("AppManage.AppManagePage.page.UploadAndroidApp")
              }}</el-button>
              <el-button @click="isHidden = 3" type="primary" size="small">{{
                $t("AppManage.AppManagePage.page.UploadiOSApp")
              }}</el-button>
              <el-button @click="isHidden = 9" type="primary" size="small">{{
                $t("AppManage.AppManagePage.page.UploadH5App")
              }}</el-button>
              <!-- v6.2.0 上传Windows应用 -->
              <el-button @click="isHidden = 10" type="primary" size="small">{{
                $t("AppManage.AppManagePage.page.uploadingWin")
              }}</el-button>
              <!-- v6.3.0 上传Linux应用 -->
              <el-button @click="isHidden = 20" type="primary" size="small">{{
                $t("AppManage.AppManagePage.page.uploadingLinux")
              }}</el-button>
              <el-button
                :disabled="hasSeleted || shelves"
                :type="typeInfo"
                size="small"
                @click="shangjia"
                >{{ $t("AppManage.AppManagePage.page.Grounding") }}</el-button
              >
              <el-button
                :disabled="hasSeleted || theShelves"
                :type="typeInfo1"
                size="small"
                @click="xiajia"
                >{{ $t("AppManage.AppManagePage.page.OffShelf") }}</el-button
              >
              <el-button
                @click="distribution1"
                :disabled="hasSeleted"
                :type="typeInfo3"
                size="small"
                >{{ $t("public.Distribution") }}</el-button
              >
              <el-button type="primary" size="small" @click="manageExport">{{
                $t("public.Derive")
              }}</el-button>
              <el-button
                :disabled="hasSeleted || deletesBtn"
                :type="typeInfo2"
                size="small"
                @click="deletes"
                >{{ $t("public.Delete") }}</el-button
              >
              <el-button
                style="margin-left: 1px"
                @click="clickStrong"
                :disabled="btnJiagu"
                :type="typeInfo5"
                size="small"
                >{{
                  $t("AppManage.AppManagePage.page.SecurityReinforcement")
                }}</el-button
              >
              <el-button
                style="margin-left: 1px"
                v-if="buttonJiance"
                @click="testShop"
                :disabled="btnJiance"
                :type="typeInfo4"
                size="small"
                >{{
                  $t("AppManage.AppManagePage.page.SecurityTesting")
                }}</el-button
              >
              <el-button
                style="margin-left: 1px"
                v-else
                @click="testShops"
                :disabled="btnJiance"
                :type="typeInfo4"
                size="small"
                >{{
                  $t("AppManage.AppManagePage.page.SecurityTesting")
                }}</el-button
              >
              <!-- <el-button @click="ComplianceTesting" :disabled="GRCJiance" :type="typeInfo7" size="small">合规检测</el-button> -->
              <!-- <el-button style="margin-left: 1px" @click="Downloadreport" :disabled="downreport" :type="typeInfo6" size="small">下载报告</el-button> -->
            </el-button-group>
          </el-col>
        </el-row>
        <el-row class="formBox" :gutter="16" style="padding: 0 20px">
          <div style="width: 100%; position: relative">
            <el-link
              type="primary"
              style="z-index: 1; position: absolute; top: -20px; right: 90px"
              @click="downFile('public/tools/iOS_OBF.zip')"
            >
              <img
                style="width: 15px; color: blue"
                src="@/images/down1.png"
                alt=""
              />
              {{ $t("AppManage.AppManagePage.page.DownloadIOS") }}
            </el-link>
            <el-link
              type="primary"
              style="z-index: 1; position: absolute; top: -20px; right: 0"
              @click="downFile('public/tools/SignTool.zip')"
            >
              {{ $t("AppManage.AppManagePage.page.DownloadSigningTool") }}
            </el-link>
          </div>
          <el-table
            stripe
            ref="multipleTables"
            :data="dictData"
            size="mini"
            @selection-change="handleSelectionChange"
            @sort-change="sort"
            :default-sort="{ prop: 'lastupdatetime', order: 'descending' }"
            style="width: 100%"
            :row-style="iRowStyle"
            :cell-style="iCellStyle"
            :row-key="getRowKeys"
            :header-row-style="iHeaderRowStyle"
            :header-cell-style="{
              background: '#f7f8fa',
              color: '#606266',
              padding: '0px',
            }"
            class="tableClass"
          >
            <el-table-column
              header-align="center"
              align="center"
              type="selection"
              width="60"
              :reserve-selection="true"
            >
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="appName"
              :label="$t('public.ApplicationName')"
              width="120"
              sortable="custom"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                <div class="el-linkStyle" @click="nameDetail(row)">
                  <img
                    v-if="row.osTypeId == 40 && row.iconPath"
                    :src="row.iconPath"
                    style="
                      width: 25px;
                      height: 25px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    alt=""
                  />
                  <img
                    v-if="row.osTypeId == 30 && row.iconPath"
                    :src="row.iconPath"
                    style="
                      width: 25px;
                      height: 25px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    alt=""
                  />
                  <img
                    v-if="row.osTypeId == 20 && row.iconPath"
                    :src="row.iconPath"
                    style="
                      width: 25px;
                      height: 25px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    alt=""
                  />
                  <img
                    v-if="row.osTypeId == 20 && !row.iconPath"
                    src="@/images/android.png"
                    style="
                      width: 25px;
                      height: 25px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    alt=""
                  />
                  <img
                    v-if="row.osTypeId == 10 && row.iconPath"
                    :src="row.iconPath"
                    style="
                      width: 25px;
                      height: 25px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    alt=""
                  />
                  <img
                    v-if="row.osTypeId == 10 && !row.iconPath"
                    src="@/images/ios.png"
                    style="
                      width: 25px;
                      height: 25px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    alt=""
                  />
                  <img
                    v-if="row.isH5App == 1 && row.iconPath"
                    :src="row.iconPath"
                    style="
                      width: 25px;
                      height: 25px;
                      display: inline-block;
                      margin-right: 5px;
                    "
                    alt=""
                  />
                  <span
                    :underline="false"
                    @click="nameDetail(row)"
                    type="warning"
                    style="width: 100px"
                  >
                    <span
                      style="color: #d38a08; width: 100px; line-height: 30px"
                      >{{ row.appName }}</span
                    >
                  </span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              :show-overflow-tooltip="true"
              header-align="left"
              align="left"
              prop="versionName"
              :label="$t('public.Version')"
              width="80"
              sortable="custom"
            >
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="appSizeStr"
              :label="$t('public.Size')"
              width="80"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <template
                  v-if="
                    row.isH5App == 1 || row.osTypeId == 30 || row.osTypeId == 40
                  "
                >
                  <span v-if="row.appSizeStr == null">/</span>
                  <span v-else>{{ row.appSizeStr }}</span>
                </template>
                <template v-else>{{ row.appSizeStr }}</template>
              </template>
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="apptype"
              :label="$t('AppManage.AppManagePage.page.InstallationType')"
              width="100"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <!-- v6.3.0  上传uploadingLinux应用 -->
                <template v-if="row.osTypeId == 40">
                  <span>/</span>
                </template>
                <template v-if="row.osTypeId == 30">
                  <template v-if="row.apptype == 1"> / </template>
                </template>
                <template
                  v-if="
                    row.osTypeId == 10 ||
                    row.osTypeId == 20 ||
                    row.osTypeId == null
                  "
                >
                  <template v-if="row.isH5App == 1"> / </template>
                  <template v-else-if="row.apptype == 1 && row.isH5App == 0">
                    {{
                      $t("AppManage.AppManagePage.page.OrdinaryApplications")
                    }}
                  </template>
                  <template v-else-if="row.apptype == 2 && row.isH5App == 0">
                    {{
                      $t("AppManage.AppManagePage.page.MandatoryApplications")
                    }}
                  </template>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="categoryName"
              :label="$t('AppManage.AppManagePage.page.applicationType')"
              width="100"
              show-overflow-tooltip
              sortable="custom"
            >
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="osTypeId"
              :label="$t('public.OperatingSystem')"
              width="110"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <template v-if="row.osTypeId == 20"> Android </template>
                <template v-if="row.osTypeId == 10"> iOS </template>
                <template v-if="row.osTypeId == 30"> Windows </template>
                <template v-if="row.osTypeId == 40"> Linux </template>
                <template v-if="row.isH5App == 1"> / </template>
              </template>
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="appstatus"
              label="应用状态"
              width="95"
              sortable="custom"
            >
              <template v-slot="{ row }">
                <template v-if="row.appstatus == 1">
                  <span>{{
                    $t("AppManage.AppManagePage.page.Notlisted")
                  }}</span>
                </template>
                <template v-if="row.appstatus == 2">
                  <span style="color: #2ba245">{{
                    $t("AppManage.AppManagePage.page.Listed")
                  }}</span>
                </template>
                <template v-if="row.appstatus == 3">
                  <span style="color: #ff0000">{{
                    $t("AppManage.AppManagePage.page.RemovedShelves")
                  }}</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectOneUse == 1"
              label="流程类型"
              width="95"
              header-align="left"
              align="left"
              sortable
              prop="ask_type"
            >
              <template v-slot="{ row }">
                <template v-if="row.askType == 0">
                  <span>/</span>
                </template>
                <template v-if="row.askType == 1">
                  <span>应用上架</span>
                </template>
                <template v-if="row.askType == 2">
                  <span>应用下架</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-if="selectOneUse == 1"
              label="流程状态"
              width="95"
              header-align="left"
              align="left"
              sortable
              prop="ask_status"
            >
              <template v-slot="{ row }">
                <template v-if="row.askStatus == 0">
                  <span>未申请</span>
                </template>
                <template v-if="row.askStatus == 2">
                  <span>审批中</span>
                </template>
                <template v-if="row.askStatus == 3">
                  <span>已通过</span>
                </template>
                <template v-if="row.askStatus == 7">
                  <span>未通过</span>
                </template>
              </template>
            </el-table-column>
            <!-- 安装模式 -->
            <el-table-column
              :label="$t('AppManage.AppManagePage.page.Installationmode')"
              width="95"
              header-align="left"
              align="left"
              sortable="custom"
              prop="installType"
            >
              <template v-slot="{ row }">
                <!-- v6.3.0  上传uploadingLinux应用 -->
                <template v-if="row.osTypeId == 40">
                  <span>/</span>
                </template>
                <template v-if="row.osTypeId == 30">
                  <span v-if="row.installType == 0">/</span>
                </template>
                <template v-if="row.osTypeId == 20">
                  <!-- 0普通安装   1安全域安装 -->
                  <span v-if="row.installType == 0">普通安装</span>
                  <span v-if="row.installType == 1">安全域安装</span>
                </template>
                <template v-if="row.osTypeId == 10 || row.isH5App == 1">
                  <span>/</span>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('AppManage.AppManagePage.page.DetectionScore')"
              sortable="custom"
              width="100"
              align="left"
              header-align="left"
              prop="scanScore"
            >
              <template v-slot="{ row }">
                <!-- v6.3.0  上传uploadingLinux应用 -->
                <template v-if="row.osTypeId == 40">
                  <span>/</span>
                </template>
                <template v-if="row.osTypeId == 30">
                  <span v-if="row.scanScore == -2">/</span>
                </template>
                <template
                  v-if="
                    row.osTypeId == 10 ||
                    row.osTypeId == 20 ||
                    row.osTypeId == null
                  "
                >
                  <template v-if="row.isH5App == 1"> / </template>
                  <template v-else-if="row.scanScore == -2 && row.isH5App == 0">
                    {{ $t("AppManage.AppManagePage.page.NotDetected") }}
                  </template>
                  <template v-else-if="row.scanScore == -1 && row.isH5App == 0">
                    {{ $t("AppManage.AppManagePage.page.Detecting") }}
                  </template>
                  <template v-else-if="row.scanScore >= 0 && row.isH5App == 0">
                    {{ row.scanScore }}分
                  </template>
                </template>
              </template>
            </el-table-column>
            <!-- 注释部分是6.1.0需求  （检测分数改为安全检测） -->
            <!-- <el-table-column label="安全检测" sortable="custom" width="110" align="left" header-align="left" prop="scanScore">
              <template v-slot="{ row }">
                <template v-if="row.scanScore == -2"> 未检测 </template>
                <template v-if="row.scanScore == -1"> 检测中 </template>
                <template v-if="row.scanScore >= 0"> {{ row.scanScore }}分 </template>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="合规检测" width="110" align="left" header-align="left" prop="scanScore">
              <template v-slot="{ row }">
                <template v-if="row.complianceStatus == -2"> 未检测 </template>
                <template v-if="row.complianceStatus == 1"> 检测中 </template>
                <template v-if="row.complianceStatus == 2"> 合规 </template>
                <template v-if="row.complianceStatus == 3"> 风险 </template>
              </template>
            </el-table-column> -->
            <el-table-column
              :label="$t('AppManage.AppManagePage.page.ReinforcementStatus')"
              width="140"
              :show-overflow-tooltip="true"
              header-align="left"
              align="left"
              sortable="custom"
              prop="purgeStatus"
            >
              <template v-slot="{ row }">
                <!-- v6.3.0  上传uploadingLinux应用 -->
                <template v-if="row.osTypeId == 40">
                  <span>/</span>
                </template>
                <template v-if="row.osTypeId == 30">
                  <span v-if="row.purgeStatus == 0">/</span>
                </template>
                <template
                  v-if="
                    row.osTypeId == 10 ||
                    row.osTypeId == 20 ||
                    row.osTypeId == null
                  "
                >
                  <template v-if="row.purgeStatus == 0 && row.isH5App == 0">
                    <span>未沙箱未加固</span>
                  </template>
                  <template v-if="row.purgeStatus == 1 && row.isH5App == 0">
                    <span>未沙箱已加固</span>
                  </template>
                  <template v-if="row.purgeStatus == 4 && row.isH5App == 0">
                    <span>未沙箱已加固(第三方)</span>
                  </template>
                  <template v-if="row.purgeStatus == 5 && row.isH5App == 0">
                    <span>未沙箱已加固(上讯)</span>
                  </template>
                  <template v-if="row.purgeStatus == 6 && row.isH5App == 0">
                    <span>已沙箱已加固</span>
                  </template>
                  <template v-if="row.purgeStatus == 7 && row.isH5App == 0">
                    <span>已沙箱未加固</span>
                  </template>
                  <template v-if="row.purgeStatus == 8 && row.isH5App == 0">
                    <span>已沙箱已加固(上讯)</span>
                  </template>
                  <template v-if="row.purgeStatus == 9 && row.isH5App == 0">
                    <span>已沙箱(上讯)未加固</span>
                  </template>
                  <template v-if="row.purgeStatus == 10 && row.isH5App == 0">
                    <span>已沙箱(上讯)已加固</span>
                  </template>
                  <template v-if="row.purgeStatus == 3 && row.isH5App == 0">
                    <!-- 加固失败 -->
                    <span>{{
                      $t("AppManage.AppManagePage.page.ReinforcementFailure")
                    }}</span>
                  </template>
                  <template v-if="row.purgeStatus == 2 && row.isH5App == 0">
                    <!-- 加固中 -->
                    <span>{{
                      $t("AppManage.AppManagePage.page.Strengthening")
                    }}</span>
                  </template>
                  <template v-if="row.isH5App == 1">
                    <span>/</span>
                  </template>
                  <!-- <template v-if="row.purgeStatus == 1 && row.isH5App == 0">
                    已加固
                    <span>{{
                      $t("AppManage.AppManagePage.page.Reinforced")
                    }}</span>
                  </template>
                  <template v-if="row.purgeStatus == 4 && row.isH5App == 0">
                    已加固（三方）
                    <span>已加固（第三方）</span>
                  </template>
                  <template v-if="row.purgeStatus == 5 && row.isH5App == 0">
                    已加固上讯
                    <span>{{ ReinforcedText }}</span>
                  </template> -->
                </template>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('AppManage.AppManagePage.page.ApplicationStrategy')"
              width="100"
              :show-overflow-tooltip="true"
              header-align="left"
              align="left"
              sortable="custom"
              prop="appPermissionName"
            >
              <template v-slot="{ row }">
                <template v-if="row.appPermissionName == null">/</template>
                <template v-else> {{ row.appPermissionName }} </template>
              </template>
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop="groupFullName"
              :label="$t('public.Section')"
              width="90"
              sortable="custom"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">
                <span v-if="row.groupFullName">
                  {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="lastupdatetime"
              :label="$t('public.ModificationTime')"
              width="140"
              sortable="custom"
              header-align="left"
              align="left"
              show-overflow-tooltip
            >
              <template v-slot="{ row }">{{ row.lastupdatetime }}</template>
            </el-table-column>
            <el-table-column
              :label="$t('public.Controls')"
              header-align="left"
              align="left"
            >
              <template v-slot="{ row }">
                <span class="action_icon">
                  <img
                    @click="azFenPei(row)"
                    src="@/assets/icon_see.png"
                    :title="
                      $t('AppManage.AppManagePage.page.InstallationAllocation')
                    "
                    alt=""
                    style="
                      width: 15px;
                      height: 15px;
                      margin-left: 5px;
                      cursor: pointer;
                    "
                  />
                </span>
                <span class="action_icon">
                  <img
                    @click="distribution(row)"
                    src="@/images/icon_delivery.png"
                    :title="$t('public.Distribution')"
                    alt=""
                    style="width: 15px; height: 15px; cursor: pointer"
                  />
                </span>
                <span class="action_icon">
                  <img
                    v-if="
                      row.osTypeId != 30 &&
                      row.isH5App == 0 &&
                      row.osTypeId != 40
                    "
                    @click="download(row)"
                    src="@/images/icon_download.png"
                    :title="$t('public.Download')"
                    alt=""
                    style="width: 15px; height: 15px; cursor: pointer"
                  />
                </span>
                <span class="action_icon">
                  <img
                    v-if="row.purgeStatus !== 2"
                    @click="eedits(row)"
                    src="../../assets/icon_write.png"
                    alt=""
                    :title="$t('public.Editor')"
                    style="width: 15px; height: 15px"
                  />
                </span>
                <span class="action_icon">
                  <img
                    v-if="row.appstatus !== 2"
                    @click="deletes(row)"
                    src="@/images/icon_del.png"
                    alt=""
                    title="删除"
                    style="width: 15px; height: 15px; cursor: pointer"
                  />
                </span>
              </template>
            </el-table-column>
          </el-table>
          <pagination
            :page="queryObj.pageNumber"
            :limit="queryObj.rowNumber"
            :total="total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          />
        </el-row>
      </div>
      <shopAEditor
        v-if="isHidden == 2"
        :disabledApk="disabledApk"
        @haldesotr="haldesotr"
        :androidData="androidData"
      />
      <shopIEditor
        v-if="isHidden == 3"
        :disabledApk="disabledApk"
        @haldesotr="haldesotr"
        :androidData="androidData"
      />
      <shoptest
        v-if="isHidden == 4"
        :dataArray="dataArray"
        :scanScore="dataArray.scanScore"
        :sidd="dataArray.id"
        @haldesotr="haldesotr3"
      ></shoptest>
      <ios-test
        v-if="isHidden == 6"
        :dataArray="dataArray"
        :scanScore="dataArray.scanScore"
        :sidd="dataArray.id"
        @haldesotr="haldesotr3"
      ></ios-test>
      <shopstrong
        v-if="isHidden == 5"
        @haldesotr="haldesotr1"
        :sidd="dataArray.id"
        :androidData="dataArray"
      ></shopstrong>
      <compliance
        @goBackSelection="goBackSelection"
        :dataArray="dataArray"
        v-if="isHidden == 8"
      />
      <portsave v-if="isHidden == 7" :searchVal="searchValArr" />
      <h5Application
        v-if="isHidden == 9"
        :disabledApk="disabledApk"
        :androidData="androidData"
        @haldesotr="haldesotr"
      />
      <uploadingWin
        v-if="isHidden == 10"
        :disabledApk="disabledApk"
        :androidData="androidData"
        @haldesotr="haldesotr"
      />
      <uploadingLinux
        v-if="isHidden == 20"
        :disabledApk="disabledApk"
        :androidData="androidData"
        @haldesotr="haldesotr"
      />
    </div>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <!-- 名称详情 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('AppManage.AppManagePage.page.ApplicationDetails')"
      :dialogVisible="dialogVisible"
      @heightCustom="heightCustom"
      @determine="determine"
      :flagbtn="false"
    >
      <namedetail @aaa="handleClose" :dictData="dataFrom" />
    </Eldialog>
    <!-- 加固详情 -->
    <Eldialog
      class="dialog"
      @handleClose="handleClose"
      :title="'加固详情'"
      :dialogVisible="jiaGuVisible"
      @heightCustom="heightCustom"
    >
      <el-row style="width: 100%" :gutter="16">
        <el-col
          style="padding: 0 40px"
          v-for="(item, index) in jiaGlist"
          :key="index"
          class="clo24"
          :span="24"
        >
          <div v-if="item.name == 'SO文件'">
            <el-checkbox v-model="checksign_type">{{ item.name }}</el-checkbox>
            <p
              style="padding: 10px 30px; color: #000"
              v-for="(item, i) in item.children"
              :key="i"
            >
              {{ item }}
            </p>
          </div>
          <div v-else>
            <el-checkbox v-model="checksign_type">{{ item.name }}</el-checkbox>
            <p style="padding: 10px 30px; color: #000" v-if="item.children">
              {{ item.children }}
            </p>
          </div>
        </el-col>
      </el-row>
    </Eldialog>
    <!-- 下载弹框 -->
    <Eldialog
      @handleClose="handleClose"
      :title="$t('AppManage.AppManagePage.page.DownloadApps')"
      :dialogVisible="downFlag"
    >
      <el-row style="width: 100%" :gutter="8">
        <el-col class="col12" :span="12">
          <el-button @click="downxiazai" type="primary">{{
            $t("AppManage.AppManagePage.page.OriginalApplication")
          }}</el-button>
        </el-col>
        <el-col class="col12" :span="12">
          <el-button
            :disabled="!statusDown"
            @click="downxiazai1"
            type="primary"
            >{{
              $t("AppManage.AppManagePage.page.ReinforcementApplication")
            }}</el-button
          >
        </el-col>
      </el-row>
    </Eldialog>
    <Eldialog
      @handleClose="handleClose"
      :title="titleInfo"
      :dialogVisible="installFlag"
      :num="'470px'"
      :width="'900px'"
    >
      <div style="position: relative; border: 1px solid #dddd; height: 450px">
        <el-tabs v-model="activeName" @tab-click="changeInstall" type="card">
          <el-tab-pane :label="$t('public.Installed')" name="first">
            <el-table
              :cell-style="{ padding: '3px 0px' }"
              :header-row-style="{ height: '30px' }"
              size="mini"
              stripe
              ref="multipleTable"
              :data="tableAn"
              class="tableClass"
              tooltip-effect="dark"
              style="width: 100%"
              :default-sort="{ prop: 'date', order: 'descending' }"
            >
              <el-table-column
                :label="$t('public.DeviceName')"
                width="165"
                show-overflow-tooltip
              >
                <template v-slot="{ row }">
                  {{ row.deviceName }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('public.Username')" width="180">
                <template v-slot="{ row }">
                  {{ row.loginName }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('public.Name')">
                <template v-slot="{ row }">
                  {{ row.userName }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('public.Section')"
                show-overflow-tooltip
              >
                <template v-slot="{ row }">
                  <span v-if="row.groupFullName">
                    {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              :page="installFenpei.currentPage2"
              :limit="installFenpei.pageSize2"
              :total="installFenpei.total"
              :pageSizes="[10, 20, 30, 40]"
              v-on:handleChildGetList="handleParentGetLists"
            />
          </el-tab-pane>
          <el-tab-pane :label="$t('public.Assigned')" name="second">
            <el-table
              :cell-style="{ padding: '3px 0px' }"
              :header-row-style="{ height: '30px' }"
              size="mini"
              stripe
              ref="multipleTable"
              :data="tableAn1"
              class="tableClass"
              tooltip-effect="dark"
              style="width: 100%"
              :default-sort="{ prop: 'date', order: 'descending' }"
            >
              <el-table-column
                prop="deviceName"
                :label="$t('public.DeviceName')"
                width="165"
                show-overflow-tooltip
              >
                <template v-slot="{ row }">
                  {{ row.deviceName }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('public.Username')" width="180">
                <template v-slot="{ row }">
                  {{ row.loginName }}
                </template>
              </el-table-column>
              <el-table-column :label="$t('public.Name')">
                <template v-slot="{ row }">
                  {{ row.userName }}
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('public.Section')"
                show-overflow-tooltip
              >
                <template v-slot="{ row }">
                  <span v-if="row.groupFullName">
                    {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
            <pagination
              :page="installFenpei.currentPage"
              :limit="installFenpei.pageSize"
              :total="installFenpei.total"
              :pageSizes="[10, 20, 30, 40]"
              v-on:handleChildGetList="handleParentGetLists2"
            />
          </el-tab-pane>
        </el-tabs>
        <el-input
          v-model="deviceName"
          @keyup.enter.native="getServerData"
          size="small"
          class="ipt"
          :placeholder="
            $t('AppManage.AppManagePage.page.placeholderDeviceName')
          "
        >
          <i
            @click="searcInstall"
            slot="suffix"
            class="el-input__icon el-icon-search"
            style="cursor: pointer"
          ></i>
        </el-input>
      </div>
    </Eldialog>
  </div>
</template>
<script>
const Base64 = require("js-base64").Base64;
import { debounce, throttle } from "@/utils/debounce.js";
import Eldialog from "@/components/elDialog";
import IosTest from "./components/iosTest.vue";
import portsave from "./components/portSave.vue";
import shoptest from "./components/shoptest.vue"; // 应用检测
import naviGation from "@/components/hearder/index";
import namedetail from "./components/shopDetail.vue"; // 详情
import shopIEditor from "./components/shopIEditor.vue"; // ios 编辑
import shopAEditor from "./components/shopAEditor.vue"; // Android  编辑
import shopstrong from "./components/shopstrong.vue"; // 应用加固
import h5Application from "./components/h5Application.vue"; // 上传h5应用
import dialogInfo from "@/components/promptMessage/index";
import pagination from "@/components/pagination/page.vue";
import Compliance from "@/components/essentialinformation/index.vue";
// v6.2.0  上传Windows应用
import uploadingWin from "./components/uploadingWin.vue"; // v6.2.0  上传Windows应用
// v6.3.0  上传Linux应用
import uploadingLinux from "./components/uploadingLinux.vue"; // v6.3.0  上传uploadingLinux应用
export default {
  components: {
    IosTest, //应用检测
    Eldialog,
    portsave,
    shoptest,
    pagination,
    naviGation,
    dialogInfo,
    namedetail, //名称详情弹框
    shopAEditor, //Android编辑
    shopIEditor, //IOS编辑
    shopstrong, //应用加固
    Compliance, //合规检测
    h5Application,
    uploadingWin, // v6.2.0  上传Windows应用
    uploadingLinux, // v6.3.0  上传uploadingLinux应用
  },
  data() {
    return {
      ReinforcedText: infoConfig.ReinforcedText,
      getDataFun: null,
      getDataFunName: null,
      selectOneUse: "",
      path: "",
      sidd: "",
      pathImg: "",
      titleInfo: "",
      deviceName: "",
      activeName: "first",
      title: "应用详情", //应用详情title
      title1: "应用编辑",
      dataArray: {},
      queryObj: {
        pageNumber: 1,
        rowNumber: 10,
        manage: 1,
        apptype: "",
        preventScreenshot: "",
        statu: "",
        installType: "",
        name: "",
        osType: "", //操作系统
        orderRule: "DESC",
        orderColume: "lastupdatetime",
      },
      installFenpei: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
        currentPage2: 1,
        pageSize2: 10,
      },
      typeInfo: "info",
      typeInfo1: "info",
      typeInfo2: "info",
      typeInfo3: "info",
      typeInfo4: "info",
      typeInfo5: "info",
      typeInfo6: "info",
      typeInfo7: "info",
      rulesHTML: "dasdasd",
      num: 220,
      total: 0,
      installId: 0,
      tableAn: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄",
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄",
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄",
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄",
        },
      ],
      disabledApk: false,
      tableAn1: [],
      checksign_type: true,
      buttonJiance: true,
      path: "",
      downFlag: false,
      checked: true,
      isHidden: 1, // 显示当前 / 编辑页面
      androidData: {}, //子组件--Android编辑
      searchValArr: {
        name: "",
        osType: "",
      }, //查询条件导出
      iosData: {}, //子组件--ios编辑
      typeInfo: "info",
      typeInfo1: "info",
      typeInfo2: "info",
      typeInfo3: "info",
      typeInfo4: "info",
      typeInfo5: "info",
      btnJiagu: true,
      rulesHTML: "dasdasd",
      num: 220,
      jiaGuVisible: false,
      flagbtn: false, //footer
      // 操作系统下拉框
      osTypeOptions: [
        {
          value: "",
          label: "操作系统",
        },
        {
          value: "20",
          label: "Android",
        },
        {
          value: "10",
          label: "iOS",
        },
        {
          value: "30",
          label: "Windows",
        },
        {
          value: "40",
          label: "Linux",
        },
      ],
      options1: [
        {
          value: "",
          label: "安装类型",
        },
        {
          value: "1",
          label: "普通应用",
        },
        {
          value: "2",
          label: "必装应用",
        },
      ],
      // 安装模式
      Installmode: [
        {
          value: "",
          label: "安装模式",
        },
        {
          value: "0",
          label: "普通安装",
        },
        {
          value: "1",
          label: "安全域安装",
        },
      ],
      options2: [
        {
          value: "",
          label: "防护类型",
        },
        {
          value: "1",
          label: "未做防护",
        },
        {
          value: "0",
          label: "安全沙箱",
        },
        {
          value: "3",
          label: "安全加固",
        },
        {
          value: "2",
          label: "加固沙箱",
        },
      ],
      options3: [
        {
          value: "",
          label: "应用状态",
        },
        {
          value: "1",
          label: "未上架",
        },
        {
          value: "2",
          label: "已上架",
        },
        {
          value: "3",
          label: "已下架",
        },
      ],
      jiaGlist: [],
      dataFrom: {}, //应用详情组件
      dictData: [],
      tableAn1: [],
      isHidden: 1, // 显示当前 / 编辑页面
      androidData: {}, //子组件--Android编辑
      iosData: {}, //子组件--ios编辑
      h5objectFenpei: {},
      multipleSelection: [],
      shelves: true,
      checked: true,
      btnJiagu: true,
      flagbtn: false,
      downFlag: false,
      btnJiance: true,
      GRCJiance: true,
      hasSeleted: true,
      theShelves: true,
      flagSearch: true,
      deletesBtn: true,
      statusDown: false,
      downreport: true,
      installFlag: false,
      buttonJiance: true,
      jiaGuVisible: false,
      checksign_type: true,
      isCheckShow: false,
      visibaelFlag: false, //提示消息默认--关闭
      dialogVisible: false, //详情弹框默认--关闭
      groupFullNameShowByIndex: "",
      vueconfig: vueConfig.jqUrl.slice(0, vueConfig.jqUrl.length - 1),
    };
  },
  created() {
    this.getDataFun = debounce(this.searcInstall, 500);
    this.getDataFunName = debounce(this.searchList, 500);
    this.selectOne();
    if (this.$route.query.jumpPageName) {
      console.log(
        this.$route.query.appType,
        this.$route.query.appname,
        "this.$route.query.appType"
      );
      if (this.$route.query.appType == "iOS") {
        this.queryObj.osType = "10";
        this.queryObj.name = this.$route.query.appname;
        this.tableList(); //应用商店table数据表格
      } else if (this.$route.query.appType == "Android") {
        this.queryObj.osType = "20";
        this.queryObj.name = this.$route.query.appname;
        this.tableList(); //应用商店table数据表格
      }
    } else {
      console.log(this.queryObj.osType);
      this.tableList(); //应用商店table数据表格
    }
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
  },
  methods: {
    getServerData(e) {
      this.getDataFun(e.target.value);
    },
    getServerName(e) {
      this.getDataFunName(e.target.value);
    },
    async selectOne() {
      // console.log(data);
      const res = await this.$axios.post(
        "/httpServe/appAudit/config/selectOne",
        {},
        true
      );
      this.selectOneUse = res.data.use;
    },
    goBackSelection() {
      this.isHidden = 1;
      this.$refs.multipleTables.clearSelection();
      this.tableList();
    },
    getRowKeys(row) {
      return row.id;
    },
    iRowStyle: function ({ row, rowIndex }) {
      //表格行高
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    testShop() {
      console.log(this.dataArray);
      if (this.dataArray.osTypeId == 20) {
        this.isHidden = 4;
      } else {
        this.isHidden = 6;
      }
    },
    testShops() {
      if (this.multipleSelection.length <= 1) {
        if (this.dataArray.parseStatus == 1) {
          this.$message({
            message: this.$t("public.Error2"),
            type: "error",
            offset: 100,
          });
          return false;
        }
      }
      this.$confirm(
        this.$t("AppManage.AppManagePage.CheckDel5"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          let data = [];
          this.multipleSelection.forEach((item) => {
            if (item.parseStatus == 0) {
              data.push(item.id + "");
            }
          });
          let params = {
            ids: data,
          };
          const res = await this.$axios.post(
            "/httpServe/entAppScan/send",
            params,
            true
          );
          console.log(res);
          if (res.code == 200) {
            // this.tabBass = true
            this.$refs.multipleTables.clearSelection();
            this.tableList();
          }
        })
        .catch(() => {
          return false;
        });
    },
    searcInstall() {
      if (this.activeName == "second") {
        this.flagSearch = false;
        this.changeInstall({ index: "1" });
        this.installFenpei.currentPage = 1;
        this.installFenpei.currentPage2 = 1;
      } else {
        this.flagSearch = false;
        this.changeInstall({ index: "0" });
        this.installFenpei.currentPage = 1;
        this.installFenpei.currentPage2 = 1;
      }
    },
    //操作列--安装/分配弹框tab页切换
    async changeInstall(val) {
      console.log(val, "val");
      if (this.flagSearch) {
        this.deviceName = "";
      }
      if (val.index == "1") {
        let params = {
          deviceName: this.deviceName,
          id: this.installId,
          currentPage: this.installFenpei.currentPage,
          pageSize: this.installFenpei.pageSize,
        };
        const res = await this.$axios.post(
          "/httpServe/entApp/deliveryDevice",
          params,
          true
        );
        this.tableAn1 = res.data.content;
        this.installFenpei.total = res.data.total;
      } else {
        if (this.h5objectFenpei.isH5App == 1) {
          let params = {
            deviceName: this.deviceName,
            id: this.installId,
            currentPage: this.installFenpei.currentPage,
            pageSize: this.installFenpei.pageSize,
          };
          const res = await this.$axios.post(
            "/httpServe/entApp/deliveryDevice",
            params,
            true
          );
          this.tableAn = res.data.content;
          this.installFenpei.total = res.data.total;
        } else {
          let params = {
            deviceName: this.deviceName,
            id: this.installId,
            currentPage: this.installFenpei.currentPage2,
            pageSize: this.installFenpei.pageSize2,
          };
          const res = await this.$axios.post(
            "/httpServe/entApp/installDevice",
            params,
            true
          );
          this.tableAn = res.data.content;
          this.installFenpei.total = res.data.total;
        }
      }
    },
    //操作列--安装/分配icon
    async azFenPei(row) {
      console.log(row, "安装/分配");
      this.h5objectFenpei = row;
      this.deviceName = "";
      this.installId = row.id;
      this.activeName = "first";
      if (row.isH5App == 1) {
        let params = {
          deviceName: this.deviceName,
          id: this.installId,
          currentPage: this.installFenpei.currentPage,
          pageSize: this.installFenpei.pageSize,
        };
        // this.titleInfo = row.appName + "安装情况";
        const res = await this.$axios.post(
          "/httpServe/entApp/deliveryDevice",
          params,
          true
        );
        if (res.data) {
          this.tableAn = res.data.content;
          this.installFenpei.total = res.data.total;
        }
      } else {
        let params = {
          deviceName: this.deviceName,
          id: this.installId,
          currentPage: this.installFenpei.currentPage2,
          pageSize: this.installFenpei.pageSize2,
        };
        // this.titleInfo = row.appName + "安装情况";
        const res = await this.$axios.post(
          "/httpServe/entApp/installDevice",
          params,
          true
        );
        if (res.data) {
          this.tableAn = res.data.content;
          this.installFenpei.total = res.data.total;
        }
      }
      this.titleInfo =
        row.appName +
        this.$t("AppManage.AppManagePage.page.AppreinforceInstallationSituation");
      this.installFlag = true;
    },
    async downFile(url) {
      var param = {
        path: url,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      console.log(res);
      await this.$axios.downloadFile(res.data);
    },
    haldesotr() {
      this.$nextTick(() => {
        this.isHidden = 1;
        this.$message({
          message: this.$t("public.SuccessfullySaved"),
          type: "success",
          offset: 100,
        });
      });
      this.androidData = {};
      this.disabledApk = false;
      this.searchList();
    },
    haldesotr1() {
      setTimeout(() => {
        this.isHidden = 1;
        this.searchList();
      }, 1000);
      this.$refs.multipleTables.clearSelection();
    },
    haldesotr3() {
      this.searchList();
      this.$message({
        message: this.$t("AppManage.AppManagePage.CheckDel7"),
        type: "success",
        offset: 100,
      });
      this.isHidden = 1;
    },
    distribution(row) {
      // 分配
      let newData = [];
      newData.push({
        value: 3,
        idList: [row.id],
      });
      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    distribution1() {
      let data = [];
      this.multipleSelection.forEach((item, index) => {
        data.push(item.id);
      });
      let newData = [];
      newData.push({
        value: 3,
        idList: data,
      });
      //判断一级 分配管理 名称是否能找到
      let arrName = this.$store.state.selectList.find(
        (item) => item.name == "Delivery"
      );
      if (arrName?.name) {
        arrName.children.forEach((item, index) => {
          if (item.name.indexOf("Delivery") != -1) {
            this.$router.push({
              name: "Delivery",
            });
          }
        });
        sessionStorage.setItem("complianceList", JSON.stringify(newData));
      }
    },
    //导出
    manageExport() {
      // console.log(this.queryObj,"导出---------------")
      this.searchValArr = { ...this.queryObj };

      this.isHidden = 7;
    },
    sort(column) {
      if (column.order === "ascending") {
        this.queryObj.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.queryObj.orderRule = "DESC";
      }
      if (column.prop == "appName") {
        this.queryObj.orderColume = "e.name";
      }
      if (column.prop == "versionName") {
        this.queryObj.orderColume = "e.versionName";
      }
      if (column.prop == "appSizeStr") {
        this.queryObj.orderColume = "e.appSize";
      }
      if (column.prop == "apptype") {
        this.queryObj.orderColume = "e.apptype";
      }
      if (column.prop == "categoryName") {
        this.queryObj.orderColume = "ec.name";
      }
      if (column.prop == "osTypeId") {
        this.queryObj.orderColume = "e.osTypeId";
      }
      if (column.prop == "appstatus") {
        this.queryObj.orderColume = "e.appstatus";
      }
      if (column.prop == "groupFullName") {
        this.queryObj.orderColume = "g.groupFullName";
      }
      if (column.prop == "lastupdatetime") {
        this.queryObj.orderColume = "e.lastupdatetime";
      }
      if (column.prop == "purgeStatus") {
        this.queryObj.orderColume = "ext.purge_status";
      }
      if (column.prop == "appPermissionName") {
        this.queryObj.orderColume = "ep.name";
      }
      if (column.prop == "ask_type") {
        this.queryObj.orderColume = "ask_type";
      }
      if (column.prop == "ask_status") {
        this.queryObj.orderColume = "ask_status";
      }
      if (column.prop == "installType") {
        this.queryObj.orderColume = "installType";
      }
      if (column.prop == "scanScore") {
        this.queryObj.orderColume = "ext.scan_score";
      }
      this.tableList();
    },
    async downxiazai() {
      var param = {
        path: this.path,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(res.data);
    },
    async downxiazai1() {
      console.log(this.path);
      const strCopy = this.path.split("/");
      strCopy[strCopy.length - 1] = "safe_" + strCopy[strCopy.length - 1];
      let data = strCopy.join("/");
      var param = {
        path: data,
      };
      const res = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      await this.$axios.downloadFile(res.data);
    },
    async download(val) {
      // 下载
      console.log(val)
      this.downFlag = true;
      this.path = val.path;
      this.statusDown = val.purgeStatus == 1||val.purgeStatus == 6 ||val.purgeStatus == 7 ||val.purgeStatus == 10 ? true : false;
    },
    jiaGuStatus(row) {
      this.jiaGlist = JSON.parse(row.purgeInfo).filter((item) => item.name);
      this.jiaGuVisible = true;
    },
    shangjia() {
      // 上架
      this.$confirm(
        this.$t("AppManage.AppManagePage.CheckDel1"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.Sshelves();
        })
        .catch(() => {
          return false;
        });
    },
    async Sshelves() {
      let data = [];
      this.multipleSelection.forEach((item, index) => {
        data.push(item.id);
      });
      let param = {
        appStatus: 2,
        ids: data,
      };
      console.log(param);
      const res = await this.$axios.post(
        "/httpServe/entApp/updateStatus",
        param,
        true
      );
      this.$message({
        message: this.$t("AppManage.AppManagePage.CheckDel4"),
        type: "success",
        offset: 100,
      });
      this.$refs.multipleTables.clearSelection();
      this.searchList();
    },
    xiajia() {
      // 下架
      this.$confirm(
        this.$t("AppManage.AppManagePage.CheckDel2"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.TheShelves();
        })
        .catch(() => {
          return false;
        });
    },
    ComplianceTesting() {
      // 合规检测
      this.isHidden = 8;
    },
    Downloadreport() {
      // 下载报告
      this.reportpdfAndDoc();
    },
    downloadFile(sdata) {
      let url = sdata;
      url = Base64.decode(url);
      url = this.vueconfig + url;
      console.log(url);
      window.open(url);
    },
    async callfudown(strArr) {
      console.log(strArr, "------");
      var param = {
        path: strArr,
      };
      console.log(param, "paramparamparamparam");
      const s = await this.$axios.post(
        "/httpServe/sysFile/fileDown",
        param,
        true
      );
      console.log(s, "s.datas.datas.data");
      let sdata = s.data;
      console.log(sdata, "sdatasdatasdatasdata");
      this.downloadFile(sdata);
    },
    async reportpdfAndDoc() {
      this.multipleSelection.forEach((i) => {
        if (i.scanScore == -2 && i.complianceStatus == -2) {
          this.$message({
            message: this.$t("AppManagePage.Error3"),
            type: "error",
            offset: 100,
          });
          return;
        }
        if (i.scanPath == null && i.compliancePath == null) {
          this.$message({
            message: this.$t("AppManagePage.Error4"),
            type: "error",
            offset: 100,
          });
          return false;
        }
        // i.compliancePath == null
        if (i.scanPath == null) {
          var strArrs = i.compliancePath.split(",");
          strArrs.forEach((j) => {
            console.log(strArrs, "1111---------");
            this.callfudown(j);
          });
          return;
        }
        if (i.compliancePath == null) {
          var strArr = i.scanPath.split(",");
          strArr.forEach((j) => {
            console.log(strArr, "1111---------");
            this.callfudown(j);
          });
          return;
        } else {
          var strArrs = i.compliancePath.split(",");
          strArrs.forEach((j) => {
            console.log(strArrs, "1111---------");
            this.callfudown(j);
          });
          var strArr = i.scanPath.split(",");
          strArr.forEach((j) => {
            console.log(strArr, "1111---------");
            this.callfudown(j);
          });
          return;
        }
      });
    },
    async clickStrong() {
      console.log(this.dataArray);
      if (this.dataArray.parseStatus == 1) {
        this.$message({
          message: this.$t("AppManagePage.Error2"),
          type: "error",
          offset: 100,
        });
        return false;
      }
      let param = {
        ids: [this.dataArray.id], //应用id
        appName: this.dataArray.appName, //应用名称
        osType: this.dataArray.osTypeId, //安卓-20 ios-10
      };
      console.log(param);
      const res = await this.$axios.post(
        "/httpServe/entApp/getPurgeInfo",
        param,
        true
      );
      console.log(JSON.parse(res.data));
      if (res.data) {
        this.dataArray.purgeInfo = res.data;
        this.isHidden = 5;
      } else {
        this.isHidden = 5;
      }
    },
    async TheShelves() {
      let data = [];
      this.multipleSelection.forEach((item, index) => {
        data.push(item.id);
      });
      let param = {
        appStatus: 3,
        ids: data,
      };
      console.log(param);
      const res = await this.$axios.post(
        "/httpServe/entApp/updateStatus",
        param,
        true
      );
      // this.titleInfo = '下架成功'
      // this.visibaelFlag = true
      this.$message({
        message: this.$t("AppManage.AppManagePage.CheckDel3"),
        type: "success",
        offset: 100,
      });
      this.$refs.multipleTables.clearSelection();
      this.searchList();
    },
    async deleteBaths(val) {
      //删除
      if (val.id) {
        let data = [];
        data.push(val.id);
        let param = {
          manage: 1,
          ids: data,
        };
        const res = await this.$axios.post(
          "/httpServe/entApp/delete",
          param,
          true
        );
        this.$message({
          message: this.$t("public.SuccessfullyDeleted"),
          type: "success",
          offset: 100,
        });
        this.$refs.multipleTables.clearSelection();
        this.searchList();
      } else {
        let data = [];
        this.multipleSelection.forEach((item, index) => {
          data.push(item.id);
        });
        let param = {
          manage: 1,
          ids: data,
        };
        const res = await this.$axios.post(
          "/httpServe/entApp/delete",
          param,
          true
        );
        if (this.multipleSelection.length > 1) {
          this.$message({
            message: this.$t("public.SuccessfullyDeleteds"),
            type: "success",
            offset: 100,
          });
          this.$refs.multipleTables.clearSelection();
          this.searchList();
        } else {
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.$refs.multipleTables.clearSelection();
          this.searchList();
        }
      }
    },
    nameDetail(val) {
      // 名称详情
      this.dialogVisible = true;
      this.dataFrom = { ...val };
    },
    eedits(val) {
      //  编辑页面
      console.log(val, "--------123");
      if (val.apptype == "2") {
        val.apptype = true;
      } else {
        val.apptype = false;
      }

      if (val.osTypeId == 20) {
        //Android
        this.isHidden = 2;
        this.disabledApk = true;
      } else if (val.osTypeId == 10) {
        //ios
        this.disabledApk = true;
        this.isHidden = 3;
      } else if (val.isH5App == 1) {
        //ios
        this.disabledApk = true;
        this.isHidden = 9;
      } else if (val.osTypeId == 30) {
        //Windows
        this.disabledApk = true;
        this.isHidden = 10;
      } else if (val.osTypeId == 40) {
        //Linux--v6.3.0
        this.disabledApk = true;
        this.isHidden = 20;
      }
      this.androidData = { ...val };
    },
    async tableList() {
      // 渲染数据列表
      var param = {
        currentPage: this.queryObj.pageNumber,
        pageSize: this.queryObj.rowNumber,
        orderColume: this.queryObj.orderColume,
        orderRule: this.queryObj.orderRule,
        appType: this.queryObj.apptype,
        appStatus: this.queryObj.statu,
        osTypeId: this.queryObj.osType,
        appName: this.queryObj.name,
        isGetH5App: 1,
        isGetParserLose: 1,
        installType: this.queryObj.installType,
        isGetSafe: 1,
      };
      const res = await this.$axios.post(
        "/httpServe/entApp/getDataInfo",
        param,
        true
      );
      console.log(res, "应用商店table表格数据");
      this.$nextTick(() => {
        this.dictData = res.data.content;
      });
      this.total = res.data.total;
    },
    searchList() {
      // 查询
      this.queryObj.pageNumber = 1;
      this.tableList();
    },
    handleSelectionChange(val) {
      this.multipleSelection = [...val];
      console.log(this.multipleSelection, "-----********************");
      if (val.length == 0) {
        this.hasSeleted = true;
        this.theShelves = true;
        this.shelves = true;
        this.deletesBtn = true;
        this.btnJiance = true;
        this.btnJiagu = true;
        this.downreport = true;
        this.GRCJiance = true;
        this.typeInfo = "info";
        this.typeInfo1 = "info";
        this.typeInfo2 = "info";
        this.typeInfo3 = "info";
        this.typeInfo4 = "info";
        this.typeInfo5 = "info";
        this.typeInfo6 = "info";
        this.typeInfo7 = "info";
      } else {
        let dataArray = [...val];
        this.dataArray = dataArray.pop();
        this.typeInfo4 = "primary";
        this.typeInfo5 = "primary";
        this.typeInfo6 = "primary";
        this.typeInfo7 = "primary";
        this.downreport = false;
        this.btnJiance = false;
        this.btnJiagu = false;
        this.GRCJiance = false;
        // 下载报告
        if (this.multipleSelection.length > 10) {
          this.typeInfo6 = "info";
          this.downreport = true;
        }
        // 合规检测
        if (this.multipleSelection.length > 1) {
          this.typeInfo7 = "info";
          this.GRCJiance = true;
        }
        //已上架的
        if (this.hasSeleted || this.shelves) {
          this.typeInfo1 = "primary";
          this.typeInfo3 = "primary";
        }
        if (this.hasSeleted || this.theShelves) {
          this.typeInfo = "primary";
          this.typeInfo1 = "info";
          this.typeInfo3 = "primary";
        }
        if (this.dataArray.isH5App == 1) {
          this.typeInfo5 = "info";
          this.btnJiagu = true;
          this.typeInfo4 = "info";
          this.btnJiance = true;
        }

        // this.dataArray.ask_status == 3 && this.dataArray.appstatus == 2
        if (this.dataArray.appstatus == 2) {
          this.hasSeleted = false;
          this.theShelves = false;
          this.shelves = true;
          this.deletesBtn = true;
          this.typeInfo = "info";
          this.typeInfo1 = "primary";
        } else if (this.dataArray.appstatus == 1) {
          this.hasSeleted = false;
          this.theShelves = true;
          this.shelves = false;
          this.deletesBtn = false;
          this.typeInfo = "primary";
          this.typeInfo1 = "info";
          this.typeInfo2 = "primary";
        } else if (this.dataArray.appstatus == 3) {
          this.hasSeleted = false;
          this.theShelves = true;
          this.shelves = false;
          this.deletesBtn = false;
          this.typeInfo2 = "primary";
        } else {
          this.shelves = true;
          this.theShelves = true;
          this.hasSeleted = false;
          this.deletesBtn = false;
          this.typeInfo = "info";
          this.typeInfo1 = "info";
          this.typeInfo2 = "primary";
        }
      }
      if (this.multipleSelection && this.multipleSelection.length >= 1) {
        console.log(
          this.multipleSelection,
          "this.multipleSelectionthis.multipleSelection"
        );
        if (
          this.multipleSelection[0].purgeStatus == 4 ||
          this.multipleSelection[0].purgeStatus == 5 ||
          this.multipleSelection[0].purgeStatus == 8 ||
          this.multipleSelection[0].purgeStatus == 2 ||
          this.multipleSelection[0].installType == 1
        ) {
          this.typeInfo5 = "info";
          this.btnJiagu = true;
        }
        if (
          this.multipleSelection[0].osTypeId == 30 ||
          this.multipleSelection[0].osTypeId == 40
        ) {
          this.typeInfo5 = "info";
          this.typeInfo4 = "info";
          this.btnJiagu = true;
          this.btnJiance = true;
        }
      }
      if (this.multipleSelection.length == 1) {
        this.buttonJiance = true;
        if (this.multipleSelection[0].scanScore == -2) {
          this.buttonJiance = false;
        }
        if (this.multipleSelection[0].scanScore == -1) {
          this.typeInfo4 = "info";
          this.btnJiance = true;
        }
      }
      if (this.multipleSelection.length >= 2) {
        this.buttonJiance = false;
        this.typeInfo5 = "info";
        this.btnJiagu = true;
        this.multipleSelection.forEach((item, index) => {
          if (this.multipleSelection[index].scanScore == -1) {
            this.typeInfo4 = "info";
            this.btnJiance = true;
          }
          if (this.multipleSelection[0].appstatus == 1 && item.appstatus == 3) {
            this.hasSeleted = false;
            this.theShelves = true;
            this.shelves = false;
            this.deletesBtn = false;
            this.typeInfo = "primary";
            this.typeInfo1 = "info";
            this.typeInfo2 = "primary";
          } else if (
            this.multipleSelection[0].appstatus == 3 &&
            item.appstatus == 1
          ) {
            this.hasSeleted = false;
            this.theShelves = true;
            this.shelves = false;
            this.deletesBtn = false;
            this.typeInfo = "primary";
            this.typeInfo1 = "info";
            this.typeInfo2 = "primary";
          } else if (this.multipleSelection[0].appstatus !== item.appstatus) {
            this.theShelves = true;
            this.shelves = true;
            this.deletesBtn = true;
            this.typeInfo = "info";
            this.typeInfo1 = "info";
            this.typeInfo2 = "info";
          }
          if (item.isH5App == 1) {
            this.typeInfo5 = "info";
            this.btnJiagu = true;
            this.typeInfo4 = "info";
            this.btnJiance = true;
          }
          // if (item.osTypeId == 30 || item.osTypeId == 40) {
          //   this.typeInfo5 = "info";
          //   this.typeInfo4 = "info";
          //   this.btnJiagu = true;
          //   this.btnJiance = true;
          // }
        });
      }
      if (this.selectOneUse == 1) {
        if (this.multipleSelection.length >= 2) {
          this.multipleSelection.forEach((item, index) => {
            if (this.multipleSelection[index].askStatus !== item.askStatus) {
              this.shelves = false;
              this.typeInfo = "primary";
              this.theShelves = false;
              this.typeInfo1 = "primary";
            } else {
              this.shelves = true;
              this.typeInfo = "info";
              this.theShelves = true;
              this.typeInfo1 = "info";
            }
          });
        }
        if (
          this.dataArray.askStatus == 0 ||
          this.dataArray.askStatus == 2 ||
          this.dataArray.askStatus == 7
        ) {
          this.shelves = true;
          this.typeInfo = "info";
          this.theShelves = true;
          this.typeInfo1 = "info";
        }
      }
    },
    handleParentGetList(page, limit) {
      // 响应分页组件查询事件
      this.queryObj.pageNumber = page;
      this.queryObj.rowNumber = limit;
      this.tableList(); // 调用查询方法
    },
    handleParentGetLists(page, limit) {
      this.installFenpei.currentPage2 = page;
      this.installFenpei.pageSize2 = limit;
      // 调用查询方法
      this.changeInstall({ index: "0" });
    },
    handleParentGetLists2(page, limit) {
      this.installFenpei.currentPage = page;
      this.installFenpei.pageSize = limit;
      this.changeInstall({ index: "1" }); // 调用查询方法
    },
    handleParentGetLists2(page, limit) {
      this.installFenpei.currentPage = page;
      this.installFenpei.pageSize = limit;
      this.changeInstall({ index: 1 }); // 调用查询方法
    },
    maskLayer() {
      //全选遮罩层
      this.isCheckShow = true;
    },
    leave() {
      // 鼠标移出弹框
      this.isCheckShow = false;
    },
    curSelection() {
      //当前页全选
      this.disabled = false;
      this.$refs.multipleTables.toggleAllSelection();
    },
    toggleSelection(rows) {
      //取消全选
      if (rows) {
        rows.forEach((row) => {
          row.forEach((item) => {
            item.checked = true;
          });
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
        this.disabled = false;
      }
      this.isCheckShow = true;
    },
    deletes(val) {
      //删除
      if (this.dataArray.complianceStatus == 1) {
        this.$message({
          message: this.$t("AppManage.AppManagePage.Error1"),
          type: "error",
          offset: 100,
        });
        this.$refs.multipleTables.clearSelection();
        return false;
      }
      this.$confirm(
        this.$t("AppManage.AppManagePage.CheckDel6"),
        this.$t("public.PromptMessage"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(() => {
          this.deleteBaths(val);
          this.$refs.multipleTables.clearSelection();
        })
        .catch(() => {
          return false;
        });
    },
    handleClose() {
      // 关闭
      this.visibaelFlag = false; //提示消息  关闭
      this.dialogVisible = false; //详情弹框  关闭
      this.jiaGuVisible = false; //加固详情
      this.downFlag = false;
      this.installFlag = false;
    },
    heightCustom() {
      this.height.height = this.num;
    },
    determine() {
      //确定回调事件
      this.visibaelFlag = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ipt {
  width: 200px;
  position: absolute;
  top: 2px;
  right: 3px;
}
:v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: none;
}
.el-linkStyle {
  display: flex;
  cursor: pointer;
  color: #d38a08;
  img {
    width: 20px;
    line-height: 20px;
  }
}
.mt15 {
  padding: 10px 10px;
}
.formBox {
  position: relative;
  .tableCheckBox {
    border: 1px solid #1e89e5;
    border-radius: 3px;
    width: 80px;
    background-color: #fff;
    position: absolute;
    top: 32px;
    left: 20px;
    z-index: 999;
    li {
      margin-top: -1px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #575656;
      cursor: pointer;
      font-weight: 400;
      font-family: Microsoft YaHei;
      font-size: 12px;
      list-style: none !important;
      div {
        display: inline-block;
      }
    }
    li:hover {
      color: #d38a08;
      text-decoration: underline;
    }
  }
}

.processManage .callenia {
  height: 0;
}
.clo24 {
  margin: 10px 0;
}
.col12 {
  text-align: center;
  margin: 60px 0;
  .el-button {
    width: 180px;
  }
}
::v-deep .el-table__empty-text {
  line-height: 0px;
  width: 60px;
  color: #909399;
}
::v-deep .el-tabs__header {
  padding: 0;
  position: relative;
  margin: 0px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}
</style>
