<template>
  <el-form
    :model="dictData"
    ref="applyDetail"
    label-width="150px"
    class="demo-ruleForm"
  > 
    <el-form-item :label="$t('public.ApplicationName')+'：'" prop="appName">
      {{ dictData.appName }}
    </el-form-item>
    <el-form-item v-if="dictData.isH5App == 0" :label="$t('public.OperatingSystem')+'：'">
      <template v-if="dictData.osTypeId == 20"> Android </template>
      <template v-if="dictData.osTypeId == 10"> iOS </template>
      <template v-if="dictData.osTypeId == 30"> Windows </template>
      <template v-if="dictData.osTypeId == 40"> Linux </template>
    </el-form-item>
    <el-form-item v-if="dictData.isH5App == 0" :label="$t('public.Size')+'：'" prop="appSizeStr">
      {{ dictData.appSizeStr }}
    </el-form-item>
    <el-form-item v-if="dictData.isH5App == 1" :label="$t('public.ApplicationID')+'：'" prop="appSizeStr">
      {{ dictData.packageName }}
    </el-form-item>
    <el-form-item :label="$t('public.Version')+'：'" prop="versionName">
      {{ dictData.versionName }}
    </el-form-item>
    <el-form-item v-if="dictData.isH5App == 0" label="MD5：" prop="md5">
      {{ dictData.md5 }}
    </el-form-item>
    <el-form-item :label="$t('AppManage.AppManagePage.DetailsPage.ApplicationCategory')+'：'" prop="categoryName">
      {{ dictData.categoryName }}
    </el-form-item>
    <el-form-item v-if="dictData.isH5App == 1" label="URL地址：" prop="urlschemes">
      {{ dictData.urlschemes }}
    </el-form-item>
    <el-form-item :label="$t('AppManage.AppManagePage.DetailsPage.ApplicationIntroduction')+'：'" prop="memo">
      {{ dictData.memo }}
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  props: {
     dictData: {
      type: Object,
      default: {},
    },
  },
  methods: {
    handleClose(){
      this.$emit('aaa')
    }
  }
};
</script>

<style lang="scss" scoped>
 .el-form-item {
  margin-bottom: 0;
}
.demo-ruleForm{
margin-left:90px;
}
.diailog-footer {
position: absolute;
bottom: 10px;
right: 10px;
}
</style>