<template>
  <div class="bigbox" style="margin-top: 30px; margin-left: 60px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        :label="$t('AppManage.AppManagePage.UploadH5App.SelectPicture')"
        prop="appName"
      >
        <el-input
          :disabled="true"
          size="small"
          v-model="ruleForm.appName"
          :placeholder="
            $t('AppManage.AppManagePage.UploadH5App.placeholderSelectPicture')
          "
          style="width: 215px"
        ></el-input>
        <el-upload
          :disabled="disabledApk"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :on-success="handleAvatarSuccess1"
          class="el-button mar-class-8"
          style="padding: 0; margin-left: 12px; border: none"
          :on-change="changeUpload"
          multiple
          accept=".png"
        >
          <el-button
            size="small"
            style="
              width: 98px;
              position: relative;
              background: #e5981e;
              border: none;
            "
          >
            <span
              style="
                cursor: pointer;
                margin-top: 5px;
                position: absolute;
                top: 1px;
              "
            >
              <img src="@/images/../images/look.png" alt="" />
            </span>
            <span style="padding-left: 18px; color: white">{{
              $t("AppManage.AppManagePage.UploadH5App.SelectPictureBtn")
            }}</span>
          </el-button>
        </el-upload>
        <span style="margin-left: 10px; color: #c0c4cc">{{
          $t("AppManage.AppManagePage.UploadH5App.Support")
        }}</span>
        <el-progress
          v-if="flagStatus"
          :percentage="percentage"
          :color="customColorMethod"
          style="width: 230px"
        ></el-progress>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.AppManagePage.UploadAndroidApp.applyName')"
        prop="department"
      >
        <el-input
          :disabled="disabledApk"
          size="small"
          style="width: 215px"
          :placeholder="
            $t('AppManage.AppManagePage.UploadAndroidApp.placeholderApplyName')
          "
          v-model.trim="ruleForm.department"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.AppManagePage.UploadAndroidApp.ApplicationID')"
        prop="AppId"
      >
        <el-input
          :disabled="disabledApk"
          size="small"
          style="width: 215px"
          :placeholder="
            $t(
              'AppManage.AppManagePage.UploadAndroidApp.placeholderApplicationID'
            )
          "
          v-model.trim="ruleForm.AppId"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('AppManage.AppManagePage.UploadAndroidApp.VersionNumber')"
        prop="version"
      >
        <el-input
          :disabled="disabledApk"
          size="small"
          style="width: 215px"
          :placeholder="
            $t(
              'AppManage.AppManagePage.UploadAndroidApp.placeholderVersionNumber'
            )
          "
          v-model="ruleForm.version"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="
          $t('AppManage.AppManagePage.UploadAndroidApp.ApplicationCategory')
        "
        prop="appcategoryid"
      >
        <el-select
          size="small"
          style="width: 215px"
          v-model="ruleForm.appcategoryid"
          :placeholder="
            $t(
              'AppManage.AppManagePage.UploadAndroidApp.placeholderApplicationCategory'
            )
          "
        >
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="
          $t('AppManage.AppManagePage.UploadAndroidApp.Apptactics') + '：'
        "
      >
        <div style="width: 380px; margin: 8px 10px 0 0px; display: flex">
          <el-table
            ref="multipleTable"
            :data="dictData"
            stripe
            size="mini"
            tooltip-effect="dark"
            class="tableClass"
            :cell-style="iCellStyle"
            :header-row-style="iHeaderRowStyle"
            :header-cell-style="{
              background: '#f7f8fa',
              color: '#606266',
              padding: '0px',
            }"
            style="width: 100%; overflow-y: auto"
          >
            <el-table-column
              header-align="left"
              align="left"
              prop="name"
              :label="
                $t('AppManage.AppManagePage.UploadAndroidApp.AndroidName')
              "
              width="120"
              show-overflow-tooltip
            >
            </el-table-column>
            <el-table-column
              header-align="left"
              align="left"
              prop=""
              :label="$t('public.Controls')"
              show-overflow-tooltip
            >
              <template>
                <img
                  @click="delapp"
                  src="@/images/icon_del.png"
                  alt=""
                  style="
                    width: 15px;
                    height: 15px;
                    margin-left: 5px;
                    cursor: pointer;
                  "
                  :title="$t('public.Delete')"
                />
              </template>
            </el-table-column>
          </el-table>

          <el-button
            @click="addData"
            type="primary"
            size="mini"
            style="margin-left: 10px; border-radius: 15px; height: 30px"
            icon="el-icon-plus"
            >{{
              $t("AppManage.AppManagePage.UploadAndroidApp.Apptactics")
            }}</el-button
          >
        </div>
      </el-form-item>
      <el-form-item
        :label="
          $t('AppManage.AppManagePage.UploadAndroidApp.ApplicationIntroduction')
        "
      >
        <el-input
          type="textarea"
          v-model="ruleForm.memo"
          style="width: 215px"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          :loading="logdingFlag"
          @click="submitForm('ruleForm')"
          type="primary"
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
    <Eldialog
      :num="'440px'"
      :width="'900px'"
      :title="$t('AppManage.AppManagePage.page.ApplicationStrategylist')"
      :dialogVisible="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
      :btnTitle="$t('public.Join')"
      :flagbtn="true"
      :cancelBtn="true"
      :determineBtn="true"
      @determine="joinType"
    >
      <div style="height: 438px">
        <el-input
          v-model="ipt"
          @input="(e) => (ipt = validForbid(e))"
          @keyup.enter.native="onSbumit"
          size="small"
          style="width: 180px; margin-bottom: 10px"
          :placeholder="$t('AppManage.AppManagePage.page.PolicyName')"
        ></el-input>
        <!-- v-model="ipt" -->
        <el-button
          @click="onSbumit"
          type="primary"
          size="small"
          style="margin-left: 3px"
          >{{ $t("public.Inquire") }}</el-button
        >
        <el-table
          size="mini"
          stripe
          ref="multipleTables"
          :data="tableDataNmae"
          :row-key="getRowKeys"
          class="sort_table"
          tooltip-effect="dark"
          style="width: 97%"
          :default-sort="{ prop: 'updateTime', order: 'descending' }"
          :header-cell-style="{ background: '#f7f8fa', color: '#606266' }"
        >
          <el-table-column align="center" width="30" :reserve-selection="true">
            <template v-slot="{ row }">
              <el-radio-group
                @change="handleSelectionChange(row)"
                v-model="ruleForm.apppermissionid"
              >
                <el-radio :label="row.id">&nbsp;</el-radio>
              </el-radio-group>
            </template>
          </el-table-column>
          <el-table-column
            prop="name"
            :label="
              $t(
                'AppManage.ApplicationStrategy.CurrentPage.PolicyList.PolicyName'
              )
            "
            width="200"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="ostypeid"
            :label="$t('public.OperatingSystem')"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <template v-if="row.osTypeId == 10"> iOS </template>
              <template v-if="row.osTypeId == 20"> Android </template>
              <template v-if="row.osTypeId == 30"> Windows </template>
              <template v-if="row.osTypeId == 40"> Linux </template>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="groupfullname"
            :label="$t('public.Section')"
            width="200"
            :show-overflow-tooltip="true"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">
                {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            style="color: #6f7982"
            prop="lastupdatetime"
            :label="$t('public.ModificationTime')"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.time == ''"></span
              ><span class="detaildesc" v-else>
                {{ scope.row.lastUpdateTime | dateformat }}
              </span>
            </template>
          </el-table-column>
        </el-table>
        <div>
          <pagination
            :page="listData.currentPage"
            :limit="listData.pageSize"
            :total="listData.total"
            :pageSizes="[10, 20, 30, 40]"
            v-on:handleChildGetList="handleParentGetList"
          />
        </div>
      </div>
    </Eldialog>
  </div>
</template>
<script>
import dialogInfo from "@/components/promptMessage/index";
import Eldialog from "@/components/elDialog";
import pagination from "@/components/pagination/page.vue"; //分页页
export default {
  components: { dialogInfo, Eldialog, pagination },
  props: {
    androidData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    disabledApk: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.selectData();
    this.Resourcesas();
    console.log(this.androidData, "this.androidDatathis.androidData");
    if (this.androidData.id) {
      // this.ruleForm = this.androidData;
      this.multipleSelection.id = this.androidData.apppermissionid;
      this.multipleSelection.name = this.androidData.appPermissionName;
      let appName = this.androidData.iconPathH5.split("/");
      this.$set(this.ruleForm, "appName", appName[appName.length - 1]);
      this.$set(this.ruleForm, "appcategoryid", this.androidData.appcategoryid);
      this.$set(this.ruleForm, "department", this.androidData.appName);
      this.$set(this.ruleForm, "version", this.androidData.versionName);
      this.$set(this.ruleForm, "memo", this.androidData.memo);
      this.$set(this.ruleForm, "AppId", this.androidData.packageName);
      this.$set(
        this.ruleForm,
        "apppermissionid",
        this.androidData.apppermissionid
      );
      this.path = "";
    }
  },
  data() {
    // 应用名称
    var validateInput = (rule, value, callback) => {
      if (!this.checkSpecappName(value)) {
        callback(
          new Error(this.$t("AppManage.AppManagePage.UploadAndroidApp.Error5"))
        );
      } else {
        callback();
      }
    };
    // 应用ID
    var validateAppId = (rule, value, callback) => {
      if (!this.checkSpecappName(value)) {
        callback(
          new Error(this.$t("AppManage.AppManagePage.UploadAndroidApp.Error7"))
        );
      } else {
        callback();
      }
    };
    // 版本号
    // var validateVersion = (rule, value, callback) => {
    //   if (this.checkSpecVersion(value)) {
    //     callback(
    //       new Error(this.$t("AppManage.AppManagePage.UploadAndroidApp.Error6"))
    //     );
    //   } else {
    //     callback();
    //   }
    // };
    // 验证域名
    const validateName = (rule, value, callback) => {
      // IP正则
      let reg =
        /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/;
      let regIpv6 =
        /^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$/;
      let valdata = value.split(",");
      let isCorrect = true;
      if (valdata.length) {
        for (let i = 0; i < valdata.length; i++) {
          if (
            reg.test(valdata[i]) == false &&
            regIpv6.test(valdata[i]) == false
          ) {
            isCorrect = false;
          }
        }
      }

      var IP =
        "^((25[0-5]|2[0-4]\\d|[1]{1}\\d{1}\\d{1}|[1-9]{1}\\d{1}|\\d{1})($|(?!\\.$)\\.)){4}$";
      var IPtest = new RegExp(IP);

      // 域名正则
      const nameRegex = /(http|https):\/\/([\w.]+\/?)\S*/;
      // /^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/

      if (!IPtest.test(value)) {
        // callback(new Error('请输入正确的ip地址'));
        this.ipFlag = 0;
        if (!nameRegex.test(value)) {
          // callback(new Error("请输入正确的域名，如：www.baidu.com"));
          // debugger;
          this.ipFlag = 0;
          callback(
            new Error(this.$t("AppManage.AppManagePage.UploadH5App.Error"))
          );
        } else {
          // this.ruleForm.gwType=1
          this.ipFlag = 1;
          if (!nameRegex.test(value)) {
            callback(
              new Error(this.$t("AppManage.AppManagePage.UploadH5App.Error1"))
            );
          } else {
            callback();
          }
        }
      } else {
        if (!isCorrect) {
          callback(
            new Error(this.$t("AppManage.AppManagePage.UploadH5App.Error2"))
          );
        } else {
          this.ipFlag = 0;
          callback();
        }
      }
    };
    return {
      logdingFlag: false,
      flagStatus: false,
      percentage: 0,
      titleInfo: "保存成功",
      visibaelFlag: false,
      checked: true, // 安装类型的多选框
      queryObj: {
        manage: 1,
        selectcont: "", //下拉矿的值
      },
      options: [],
      ruleForm: {
        appName: "", //
        region: "", //
        selectcont: "", //下拉矿的值
        appcategoryid: "",
        osType: "40",
        department: "",
        AppId: "",
        version: "",
        urls: "",
        memo: "",
        name: "",
        apppermissionid: "",
      },
      path: "",
      rules: {
        appName: [
          {
            required: true,
            message: this.$t(
              "AppManage.AppManagePage.UploadH5App.rules.picture"
            ),
            trigger: ["blur", "change"],
          },
        ],
        appcategoryid: [
          {
            required: true,
            message: this.$t(
              "AppManage.AppManagePage.UploadAndroidApp.rules.appcategoryid"
            ),
            trigger: "change",
          },
        ],
        // 应用名称
        department: [
          {
            required: true,
            message: this.$t(
              "AppManage.AppManagePage.UploadH5App.rules.appName"
            ),
            trigger: "blur",
          },
          { validator: validateInput, trigger: ["blur", "change"] },
        ],
        AppId: [
          {
            required: true,
            message: this.$t(
              "AppManage.AppManagePage.UploadAndroidApp.rules.packageName"
            ),
            trigger: "blur",
          },
          { validator: validateAppId, trigger: ["blur", "change"] },
        ],
        version: [
          {
            required: true,
            message: this.$t(
              "AppManage.AppManagePage.UploadAndroidApp.rules.versionName"
            ),
            trigger: "blur",
          },
          // { validator: validateVersion, trigger: ["blur", "change"] },
        ],
        name: [
          {
            required: true,
            message: this.$t(
              "AppManage.AppManagePage.UploadAndroidApp.rules.name"
            ),
            trigger: "blur",
          },
        ],
      },
      radio: "", //v6.1.1新增
      statusVisible: false, //添加弹框
      dictData: [], //应用策略  如果是[{}]就会多一个空数据
      ipt: "", //弹框搜索
      tableDataNmae: [], //资源列表
      // 列表参数
      listData: {
        total: "",
        currentPage: 1,
        pageSize: 10,
        orderColume: "lastUpdateTime",
        orderRule: "DESC",
      },
      name: "",
      groupFullNameShowByIndex: "", //用户部门截取的下标
      multipleSelection: {},
      waters: "",
      purgeStatus: 0,
    };
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    setTimeout(() => {
      this.ruleForm.apppermissionid = this.ruleForm.apppermissionid + "";
      this.edittableDataNmae.forEach((item, index) => {
        if (item.id == this.ruleForm.apppermissionid) {
          this.dictData.push({
            id: item.id,
            name: item.name,
          });
        }
      });
      this.$forceUpdate();
    }, 1500);
  },
  methods: {
    checkSpecappName(str) {
      var specialKey =
        "[`~!#$^&*()=|+{}':;',\\[\\]<>/?~！#￥……&*（）——%@|{}【】‘；：”“'。，、？’]‘'";
      for (var i = 0; i < str.length; i++) {
        if (specialKey.indexOf(str.substr(i, 1)) != -1) {
          return false;
        }
      }
      return true;
    },
    checkSpecificKey(str) {
      var specialAppId = /^[0-9a-zA-Z]+(\.[0-9a-zA-Z]+)+$/;
      var specialAppIds = /^[0-9a-zA-Z]+$/;
      if (specialAppId.test(str)) {
        return false;
      }
      if (specialAppIds.test(str)) {
        return false;
      }
      return true;
    },
    checkSpecVersion(str) {
      // var specialKey =/^([a-zA-Z0-9]){1}(\w|\.|\s*)+$/
      var specialone = /^[0-9A-Za-z]+(\.[0-9A-Za-z]+)+$/;
      var specialtwo = /^[0-9A-Za-z]+(\.[0-9A-Za-z]+){2}$/;
      var specialtree = /^[0-9A-Za-z]+(\.[0-9A-Za-z]+){3}$/;
      if (specialone.test(str)) {
        console.log(121312312);
        return false;
      }
      if (specialtwo.test(str)) {
        return false;
      }
      if (specialtree.test(str)) {
        return false;
      }
      return true;
    },
    mathdom() {
      var num = Math.floor(Math.random() * 10 + 1);
      return num;
    },
    customColorMethod(percentage) {
      if (percentage < 30) {
        return "#909399";
      } else if (percentage < 70) {
        return "#e6a23c";
      } else {
        return "#67c23a";
      }
    },
    async saveSubmit() {
      this.logdingFlag = true;
      if (this.androidData.id) {
        if (this.waters == "" || this.waters == undefined) {
          console.log(this.dictData, "this.dictData");
          if (this.dictData == undefined || this.dictData.length == 0) {
            let watint = "";
            this.waters = watint;
          } else {
            this.waters = this.dictData[0].id;
          }
        } else if (this.waters != "") {
          console.log(this.waters, "this.waters");
          this.waters = this.waters[0].id;
        }
        let params = {
          id: this.androidData.id + "",
          appCategoryId: this.ruleForm.appcategoryid,
          memo: this.ruleForm.memo,
          apppermissionid: this.waters, //应用策略id  如果不选择就为0
        };
        // console.log(params)
        const res = await this.$axios.post(
          "/httpServe/entApp/updateExe",
          params,
          true
        );
        if (res.data == 0) {
          this.logdingFlag = false;
          this.$message({
            message: this.$t("AppManage.AppManagePage.UploadAndroidApp.Error"),
            type: "error",
            offset: 100,
          });
        }
        if (res.data == 1) {
          this.$refs.ruleForm.resetFields();
          this.ruleForm = {};
          this.ruleForm.memo = "";
          this.logdingFlag = false;
          this.$emit("haldesotr");
        }
      } else {
        if (this.waters != "") {
          let watID = this.waters[0].id;
          this.waters = watID;
          console.log(this.waters, "this.waters");
        } else if (this.waters == "") {
          let watint = "";
          this.waters = watint;
          console.log(this.waters, "watintwatintwatint");
        }
        let params = {
          appName: this.ruleForm.department,
          packageName: this.ruleForm.AppId,
          versionName: this.ruleForm.version,
          appCategoryId: this.ruleForm.appcategoryid,
          filePath: this.path,
          memo: this.ruleForm.memo,
          osType:40,			//6.3.0同步修改保存Windows应用页面，增加osType参数，30-Windows应用 40-Linux 应用
          apppermissionid: this.waters, //应用策略id  如果不选择就为0
        };
        const res = await this.$axios.post(
          "/httpServe/entApp/saveExe",
          params,
          true
        );
        if (res.data == 0) {
          this.logdingFlag = false;
          this.$message({
            message: this.$t("AppManage.AppManagePage.UploadAndroidApp.Error"),
            type: "error",
            offset: 100,
          });
          this.logdingFlag = false;
        }
        if (res.data == 1) {
          this.$refs.ruleForm.resetFields();
          this.ruleForm = {};
          this.ruleForm.memo = "";
          this.logdingFlag = false;
          this.$emit("haldesotr");
        }
      }
    },
    async selectData() {
      // 应用类别----下拉框接口
      var param = {
        currentPage: 1,
        pageSize: 999999,
        appCategory: "",
      };
      const res = await this.$axios.post(
        "/httpServe/entAppCategory/getDataInfo",
        param,
        true
      );
      // console.log(res)
      this.options = res.data.content;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.saveSubmit();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getFileType(name) {
      let startIndex = name.lastIndexOf(".");
      if (startIndex !== -1) {
        return name.slice(startIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    handleClose() {
      this.visibaelFlag = false;
      this.ipt = "";
      this.listData.currentPage = 1;
      this.statusVisible = false;
    },
    //文件上传接口
    async changeUpload(file) {
      this.percentage = 0;
      this.flagStatus = true;
      let suffix = this.getFileType(file.name); //获取文件后缀名
      let suffixArray = ["png"]; //限制的文件类型，根据情况自己定义
      if (suffixArray.indexOf(suffix) === -1) {
        this.$set(this.ruleForm, "appName", "");
        this.flagStatus = false;
        this.$message({
          message: this.$t("AppManage.AppManagePage.UploadH5App.Error3"),
          type: "error",
          offset: 100,
        });
      } else if (file.size / (1024 * 1024) > 0.3) {
        //   限制文件大小
        this.$message.warning(
          this.$t("AppManage.AppManagePage.UploadH5App.Error4")
        );
        this.flagStatus = false;
        return false;
      } else {
        const form = new FormData();
        // 文件对象
        form.append("files", file.raw);
        form.append("type", "protected");
        form.append("fileType", "img");
        form.append("moduleName", "protected");
        // const res = await this.$axios.upload("/httpServe/sysFile/fileUp", form, false);
        const res = await this.$axios.upload(
          "/httpServe/sysFile/fileUp",
          form,
          this.updateProgress,
          true
        );
        if (res.data.code == 500) {
          this.$message({
            message: "返回上传地址信息失败！",
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
        }
        this.path = res.data.data[0];

        if (this.path) {
          // this.titleInfo = '上传应用成功'
          // this.visibaelFlag = true
          this.$message({
            message: this.$t("AppManage.AppManagePage.UploadH5App.Success"),
            type: "success",
            offset: 100,
          });
          this.flagStatus = false;
        } else {
          console.log(321);
          // this.titleInfo = '上传应用失败'
          // this.visibaelFlag = true
          this.$message({
            message: this.$t("AppManage.AppManagePage.UploadAndroidApp.Error3"),
            type: "error",
            offset: 100,
          });
          this.flagStatus = false;
        }
        this.$nextTick(() => {
          this.$set(this.ruleForm, "appName", file.name);
        });
        if (res.code == 200) {
          this.$emit("haldesotr");
        }
      }
    },
    updateProgress(e) {
      //e为回调回来的参数 通过进行和total的值来进行进度
      this.percentage = parseInt((e.loaded / e.total) * 100);
    },
    handleAvatarSuccess1() {},
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    // 添加应用策略
    addData() {
      this.listData.currentPage = 1;
      this.statusVisible = true;
      this.Resources();
    },
    // 添加资源列表
    async Resources() {
      // if (this.ipt.length > 0) {
      //   this.listData.currentPage = 1
      // }
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: this.listData.pageSize,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        name: this.ipt,
        osTypeId: 40,
      };
      console.log(param, "paramparam");
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/getDataInfo",
        param,
        true
      );
      console.log(res, "eeeeeeeeeeeee");
      this.tableDataNmae = res.data.content.filter((item) => {
        // console.log(item.osTypeId===20,"00000110");
        // return item.osTypeId != 10;
        return item.osTypeId == 40;
      }); //列表数据
      console.log(res.data.content, "网关资源列表数据");
      this.listData.total = res.data.total; //列表总数
      console.log(this.dictData, "this.dictData");
      this.$emit("xingwei", this.permission);
    },
    // 编辑添加资源列表
    async Resourcesas() {
      var param = {
        currentPage: this.listData.currentPage,
        pageSize: 9999,
        orderColume: this.listData.orderColume,
        orderRule: this.listData.orderRule,
        name: this.ipt,
        osTypeId: 40,
      };
      console.log(param, "paramparam");
      const res = await this.$axios.post(
        "/httpServe/entAppPermission/getDataInfo",
        param,
        true
      );
      console.log(res, "eeeeeeeeeeeee");
      this.edittableDataNmae = res.data.content.filter((item) => {
        // console.log(item.osTypeId===20,"00000110");
        // return item.osTypeId != 10;
        return item.osTypeId == 40;
      }); //列表数据
      console.log(res.data.content, "网关资源列表数据");
      this.listData.total = res.data.total; //列表总数
      console.log(this.dictData, "this.dictData");
      this.$emit("xingwei", this.permission);
    },
    heightCustom() {
      this.height.height = this.num;
    },
    // 添加资源   选中
    handleSelectionChange(val) {
      console.log(val, "val添加资源");
      this.multipleSelection = val;
    },
    // 添加
    joinType() {
      this.dictData = [];
      console.log(this.multipleSelection.id, "his.multipleSelection.id");
      if (this.multipleSelection.id) {
        this.dictData.push({
          id: this.multipleSelection.id,
          name: this.multipleSelection.name,
        });
      }
      this.waters = [];
      this.waters.push({
        id: this.multipleSelection.id,
      });
      console.log(this.waters, "apppermissionid:this.waters.id,//应用策略id");
      // this.waters.forEach((i)=>{
      //   if(i.id==undefined){
      //     console.log(i.id,'23e23e23e23');
      //     this.dictData = [];
      //   }
      // })
      this.statusVisible = false;
    },
    delapp() {
      this.waters = this.dictData = undefined;
      this.multipleSelection.id = "";
      this.ruleForm.apppermissionid = "";
      this.dictData = [];
    },
    // 资源列表查询
    onSbumit() {
      this.listData.currentPage = 1;
      this.Resources();
    },
    // 弹框全选
    getRowKeys(row) {
      return row.id;
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.listData.currentPage = page;
      this.listData.pageSize = limit;
      // 调用列表
      this.Resources();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}
</style>
